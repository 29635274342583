import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { FeedbackFormData } from 'src/modules/feedback/types/FeedbackFormData';
import { call } from 'typed-redux-saga';

export function* sendFeedback(formData: FeedbackFormData): SagaIterator<void> {
  try {
    yield* call(updateUserLatestActivity);

    yield* call(makeRestRequest, 'POST', '/feedback', formData);
  } catch (error) {
    throw new RuntimeError('Could not send feedback', { formData }, NativeError.wrap(error));
  }
}
