import { SagaIterator } from 'redux-saga';
import { SCHEMA_SELECTION_FETCH } from 'src/modules/schema/actions/SchemaActions';
import { loadSchemaSelection } from 'src/modules/schema/sagas/utils/loadSchemaSelection';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* fetchSchemaSelection(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching schema selection...');

    const selection = yield* call(loadSchemaSelection);
    yield* put(SCHEMA_SELECTION_FETCH.trigger(selection));

    yield* call(logDebug, 'Fetching schema selection... done', selection);
  } catch (error) {
    yield* call(logError, 'Fetching schema selection... error', error);
    throw error;
  }
}
