import './UsersListBody.scss';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { DeleteIcon } from 'src/modules/common/icons/DeleteIcon';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { UserListActions } from 'src/modules/users/components/UserListActions';
import { UserListHeader } from 'src/modules/users/components/UserListHeader';
import { UserRow } from 'src/modules/users/components/UserRow';
import { SortingOrder, SortingTarget } from 'src/modules/users/types/Sorting';
import { User } from 'src/modules/users/types/User';
import { UserDeleting } from 'src/modules/users/types/UserDeleting';
import { DeleteUserModal } from 'src/modules/users/views/DeleteUserModal';

type Props = {
  readonly searchQuery: string;
  readonly setSearchQuery: (query: string) => void;
  readonly sortingTarget: SortingTarget;
  readonly setSortingTarget: (target: SortingTarget) => void;
  readonly sortingOrder: SortingOrder;
  readonly setSortingOrder: (order: SortingOrder) => void;
  readonly sortedFoundUsers: ReadonlyArray<User>;
  readonly sortedFoundSelectableUsers: ReadonlyArray<User>;
  readonly loggedInUser: AuthUser;
  readonly userDeleting: UserDeleting | null;
  readonly handleDelete: (data: ReadonlyArray<User>) => void;
  readonly handleAddUserOpen: () => void;
};

export const UsersListBody = memo(({
  searchQuery,
  setSearchQuery,
  sortingTarget,
  setSortingTarget,
  sortingOrder,
  setSortingOrder,
  sortedFoundUsers,
  sortedFoundSelectableUsers,
  loggedInUser,
  userDeleting,
  handleDelete,
  handleAddUserOpen,
}: Props): React.ReactElement => {
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<ReadonlyArray<User>>([]);

  const areSomeSelected = selectedUsers.length !== 0 &&
    selectedUsers.length !== sortedFoundSelectableUsers.length;
  const areAllSelected = selectedUsers.length !== 0 &&
    selectedUsers.length === sortedFoundSelectableUsers.length;

  const handleSelectAll = (): void => {
    setSelectedUsers(sortedFoundSelectableUsers);
  };

  const handleSelectNone = (): void => {
    setSelectedUsers([]);
  };

  const handleHeaderCheckboxChange = (): void => {
    if (areAllSelected) {
      return setSelectedUsers([]);
    }

    return setSelectedUsers(sortedFoundSelectableUsers);
  };

  const handleDeleteUserCancel = (): void => {
    setDeleteUserModalOpen(false);
  };

  const handleDeleteUserSubmit = (): void => handleDelete(selectedUsers);

  const handleSelect = useCallback((selectedUser: User): void => {
    const selectedUserIds = selectedUsers.map((user) => user.id);
    const checked = selectedUserIds.includes(selectedUser.id);

    if (!checked) {
      return setSelectedUsers((prev) => [...prev, selectedUser]);
    }

    const filteredUsers = selectedUsers.filter((user) => user.id !== selectedUser.id);

    return setSelectedUsers(filteredUsers);
  }, [selectedUsers]);

  useEffect(() => {
    const allSelectableUserIds = sortedFoundSelectableUsers.map((user) => user.id);
    setSelectedUsers((prev) => prev.filter((user) => allSelectableUserIds.includes(user.id)));
  }, [sortedFoundSelectableUsers]);

  useEffect(() => {
    if (userDeleting?.status.state === 'success') {
      setDeleteUserModalOpen(false);
    }
  }, [userDeleting?.status]);

  return (
    <div className="bp-users-list-body">
      <div className="bp-users-list-body__content">

        {selectedUsers.length !== 0 ? (
          <div className="bp-users-list-body__selections">
            <div className="bp-users-list-body__selection-group">
              <FormattedMessage id="admin/users/list/selected" values={{ count: selectedUsers.length }}/>

              <div className="bp-users-list-body__selection-divider"/>

              <div className="bp-users-list-body__selection-button">
                <CustomButton size="md" onClick={handleSelectAll}>
                  <FormattedMessage
                    id="admin/users/list/selectAll"
                    values={{ count: sortedFoundSelectableUsers.length }}
                  />
                </CustomButton>
              </div>
            </div>

            <div className="bp-users-list-body__selection-group">
              <CustomButton size="md" onClick={() => setDeleteUserModalOpen(true)}>
                <FormattedMessage id="admin/users/list/delete"/>

                <div className="bp-users-list-body__icon">
                  <DeleteIcon/>
                </div>
              </CustomButton>

              <div className="bp-users-list-body__selection-divider"/>

              <CustomButton size="md" onClick={handleSelectNone}>
                <FormattedMessage id="admin/users/list/cancel"/>
              </CustomButton>
            </div>
          </div>
        ) : (
          <UserListActions
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleAddUserOpen={handleAddUserOpen}
          />
        )}

        <UserListHeader
          areAllSelected={areAllSelected}
          areSomeSelected={areSomeSelected}
          sortingOrder={sortingOrder}
          sortingTarget={sortingTarget}
          setSortingOrder={setSortingOrder}
          setSortingTarget={setSortingTarget}
          handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        />

        {sortedFoundUsers.map((user) => (
          <UserRow
            key={user.id}
            isSelf={loggedInUser.id === user.id}
            currentUser={user}
            isSelected={selectedUsers.map((selectedUser) => selectedUser.id).includes(user.id)}
            onSelectHandler={handleSelect}
          />
        ))}

        <CustomModal
          isShaded={false}
          danger={true}
          onClose={handleDeleteUserCancel}
          open={isDeleteUserModalOpen}
          shouldUnmount={true}
          size="sm"
        >
          <DeleteUserModal
            removing={userDeleting !== null && userDeleting.status.state === 'pending'}
            selectedUsers={selectedUsers}
            onDelete={handleDeleteUserSubmit}
            onClose={handleDeleteUserCancel}
          />
        </CustomModal>
      </div>
    </div>
  );
});
