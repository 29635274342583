import './LoginLayout.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  readonly children: React.ReactNode;
};

export const LoginLayout = ({ children }: Props): React.ReactElement => (
  <div className="bp-login-layout">
    <div className="bp-login-layout__column bp-login-layout__column--gray">
      <img
        alt="Logo"
        src="/images/logo.svg"
        height={29}
        className="bp-login-layout__logo"
      />

      <div className="bp-login-layout__contents">
        <div className="bp-login-layout__company">
          <h2 className="bp-login-layout__brand">
            <FormattedMessage id="login/mainLoginText"/>
          </h2>

          <LeadText className="bp-login-layout__description">
            <FormattedMessage id="login/subLoginText"/>
          </LeadText>
        </div>

        <img
          src="/images/LoginPagePicture.svg"
          className="bp-login-layout__picture"
          alt=""
        />
      </div>
    </div>

    <div className="bp-login-layout__column bp-login-layout__column--white">
      {children}
    </div>
  </div>
);
