import './DateboxField.scss';
import './Field.scss';
import { DatePicker, DatePickerInput } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { dateFormat } from 'src/modules/common/constants/dateFormat';
import { DateIcon } from 'src/modules/common/icons/DateIcon';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData> = {
  readonly size: 'sm' | 'md' | 'lg';
  readonly label: string;
  readonly field: FieldPath<TData, FormError, Date | null>;
  readonly renderError?: (error: FormError) => React.ReactNode;
};

export const DateboxField: <TData>(props: Props<TData>) => React.ReactElement = (
  { size, field, label, renderError = renderErrorDefault },
) => {
  const intl = useIntl();

  const { value, meta, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((newValue: Date[]) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(newValue[0] ? newValue[0] : null),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <div className="bp-datebox-field-wrapper">
      <DatePicker
        className={clsx(
          'bp-field',
          'bp-datebox-field',
          `bp-datebox-field--${size}`,
          {
            'bp-field--invalid': invalid,
          },
        )}
        dateFormat={dateFormat}
        value={value ? dayjs(value).startOf('day').format(intl.formatMessage({ id: 'common/dateFormat' })) : undefined}
        invalid={invalid}
        invalidText={error && invalid ? renderError(error) : null}
        onChange={handleChange}
        onClose={handleBlur}
        datePickerType="single"
      >
        <DatePickerInput
          id={`${field.form.name}.${field.path}`}
          placeholder={intl.formatMessage({ id: 'common/datePlaceholder' })}
          labelText={label}
        />
      </DatePicker>

      <div className="bp-datebox-field__icon">
        <DateIcon/>
      </div>
    </div>
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
);
