import './SchemaClearContainersConfirmation.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
};

export const SchemaClearContainersConfirmation = ({ isOpen, onCancel, onSubmit }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <CustomModal
      className="bp-schema-clear-containers-confirmation"
      open={isOpen}
      isShaded={false}
      onClose={onCancel}
      danger={true}
      size="sm"
      shouldUnmount={true}
    >
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        labelClassName="bp-schema-clear-containers-confirmation__label"
        label={intl.formatMessage({ id: 'schemaEdit/clearContainers/title' })}
      />

      <ModalBody>
        <div className="bp-schema-clear-containers-confirmation__body">
          <LeadText>
            <FormattedMessage id="schemaEdit/clearContainers/subtitle"/>
          </LeadText>

          <BodySmallText>
            <FormattedMessage id="schemaEdit/clearContainers/description"/>
          </BodySmallText>
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton kind="secondary" autoFocus={true} onClick={onCancel}>
          <FormattedMessage id="schemaEdit/clearContainers/cancel"/>
        </CustomButton>

        <CustomButton kind="primary" onClick={onSubmit}>
          <FormattedMessage id="schemaEdit/clearContainers/confirm"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
