import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { SCHEMA_COMMENT_CHANGE } from 'src/modules/schema/actions/SchemaActions';
import { getSchemaFormData } from 'src/modules/schema/selectors/getSchemaFormData';
import { SchemaFormData } from 'src/modules/schema/types/SchemaFormData';
import { mapSchemaFormDataToProject } from 'src/modules/schema/utils/mapSchema';
import { resolveReportSchemaSelection } from 'src/modules/schema/utils/resolveReportSchemaSelection';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertNotNull } from 'src/utils/assert';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* schemaCommentChangeSaga(
  action: GetTriggerActionType<typeof SCHEMA_COMMENT_CHANGE>,
): SagaIterator<void> {
  const comment = action.data;
  const area = action.meta;

  try {
    yield* call(logDebug, 'Saving schema area comment...', comment);

    const previous = yield* select(getProjectDetails);

    const previousProjectSchemaArea = assertNotNull(
      previous.data.schema[area],
      'Could not find schema to change',
    );

    const previousSchema = yield* select(getSchemaFormData, previous);

    const previousSchemaArea = assertNotNull(
      previousSchema[area],
      'Could not find schema to change',
    );

    const modifiedSchema: SchemaFormData = {
      ...previousSchemaArea,
      comment,
    };

    const schemaSelectionResult = yield* call(mapSchemaFormDataToProject, modifiedSchema);

    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        schema: {
          ...previous.data.schema,
          [area]: {
            ...schemaSelectionResult,
            matrix: previousProjectSchemaArea.matrix,
          },
        },
      },
    };

    const modifiedReportProjectData = yield* call(resolveReportSchemaSelection, previous.data, modified.data, area);

    const modifiedReportProject: Project = {
      ...previous,
      data: modifiedReportProjectData,
    };

    const reloaded = yield* call(syncProjectBackground, modifiedReportProject);
    yield* call(logDebug, 'Saving schema area comment... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save schema area comment', { comment }, NativeError.wrap(error));
    yield* call(logError, 'Saving schema area comment... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
