import './PasswordForgotScreen.scss';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { PASSWORD_FORGOT_FORM_SCHEMA } from 'src/modules/password-forgot/constants/schema';
import { PasswordForgotFormData } from 'src/modules/password-forgot/types/PasswordForgotFormData';
import { PasswordForgotForm } from 'src/modules/password-forgot/views/PasswordForgotForm';

type Props = {
  readonly onSubmit: (form: FormController<PasswordForgotFormData, FormError>) => void;
};

export const PasswordForgotScreen = ({ onSubmit }: Props): React.ReactElement => {
  const form = useForm<PasswordForgotFormData, FormError>('password.forgot', {
    reinitialize: false,
    initialValues: { username: '' },
  });
  useFormValidator(form, PASSWORD_FORGOT_FORM_SCHEMA);

  const isSubmitted = useFormWatch(form, form.submitSucceded.get);
  const handleSubmit = useCallback(() => {
    onSubmit(form);
  }, [form, onSubmit]);

  return (
    <div className="bp-password-forgot-screen">
      <h4 className="bp-password-forgot-screen__title">
        <FormattedMessage id="passwordForgot/title"/>
      </h4>

      <p className="bp-password-forgot-screen__subtitle">
        {isSubmitted
          ? <FormattedMessage id="passwordForgot/success"/>
          : <FormattedMessage id="passwordForgot/subtitle"/>}
      </p>

      {!isSubmitted && (
        <div className="bp-password-forgot-screen__form">
          <PasswordForgotForm
            form={form}
            onSubmit={handleSubmit}
          />
        </div>
      )}

      <div className="bp-password-forgot-screen__back">
        <CustomButton kind="ghost" as={Link} to="/login" size="md">
          <ArrowLeftIcon/>
          <FormattedMessage id="passwordForgot/goBack"/>
        </CustomButton>
      </div>
    </div>
  );
};
