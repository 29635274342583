import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { ProjectToolbar } from 'src/modules/common/components/ProjectToolbar';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { getConfigSchemas } from 'src/modules/config/selectors/getConfigSchemas';
import { PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import {
  SCHEMA_COMMENT_CHANGE,
  SCHEMA_MATRIX_CHANGE,
  SCHEMA_TEMPLATE_SELECT,
  SCHEMA_VERSION_CHANGE,
  SCHEMA_VERSION_CREATE,
  SCHEMA_VERSION_REMOVE,
  SCHEMA_SELECTION_CHANGE,
} from 'src/modules/schema/actions/SchemaActions';
import { getSchemaFormData } from 'src/modules/schema/selectors/getSchemaFormData';
import { getSchemaSelection } from 'src/modules/schema/selectors/getSchemaSelection';
import { MultipleSchemasScreen } from 'src/modules/schema/views/MultipleSchemasScreen';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const SchemaPage = (): React.ReactElement => {
  const intl = useIntl();

  const project = useSelector(getProjectDetails);
  const schemas = useParametricSelector(getSchemaFormData, project);

  const templates = useSelector(getConfigSchemas);
  const selectTemplate = useAction(SCHEMA_TEMPLATE_SELECT.trigger);

  const selection = useSelector(getSchemaSelection);
  const changeSelection = useAction(SCHEMA_SELECTION_CHANGE.request);

  const createVersion = useAction(SCHEMA_VERSION_CREATE.trigger);
  const changeVersion = useAction(SCHEMA_VERSION_CHANGE.trigger);
  const removeVersion = useAction(SCHEMA_VERSION_REMOVE.trigger);
  const changeMatrix = useAction(SCHEMA_MATRIX_CHANGE.trigger);
  const changeComment = useAction(SCHEMA_COMMENT_CHANGE.trigger);

  const shareProject = useBoundAction(PROJECT_VIEW_SHARE.trigger, project);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'schema/pageTitle' })}
        </title>
      </Helmet>

      <AppLayout>
        <ProjectToolbar
          project={project}
          onShare={shareProject}
        />

        <MultipleSchemasScreen
          projectId={project.id}
          schemas={schemas}
          templates={templates}
          selection={selection}
          onSelectTemplate={selectTemplate}
          onChangeSelection={changeSelection}
          onCreateVersion={createVersion}
          onChangeVersion={changeVersion}
          onRemoveVersion={removeVersion}
          onChangeComment={changeComment}
          onChangeMatrix={changeMatrix}
        />
      </AppLayout>

      <ShareProject/>
    </>
  );
};
