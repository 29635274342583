import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { ThermalEnergy } from 'src/modules/common/types/ThermalEnergy';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';
import { SchemaSelection } from 'src/modules/schema/types/SchemaSelection';

export const SCHEMA_CONTAINER_SIZE = {
  x: 100,
  y: 100,
};

export const SCHEMA_AREA_MAP: Record<ThermalEnergy, SchemaArea> = {
  [ThermalEnergy.HEATING]: SchemaArea.HEATING,
  [ThermalEnergy.COOLING]: SchemaArea.COOLING,
  [ThermalEnergy.MIXED]: SchemaArea.HEATING, // TODO: ?!
};

export const DEFAULT_SCHEMA_SELECTION: SchemaAreaMap<SchemaSelection> = {
  [SchemaArea.HEATING]: null,
  [SchemaArea.COOLING]: null,
  [SchemaArea.VENTILATION]: null,
};
