import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { call } from 'typed-redux-saga';

export function* performLogout(): SagaIterator<void> {
  try {
    yield* call(updateUserLatestActivity);

    yield* call(makeRestRequest, 'DELETE', '/auth/logout');
  } catch (error) {
    throw new RuntimeError('Could not perform logout', {}, NativeError.wrap(error));
  }
}
