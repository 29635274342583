import './UserListActions.scss';
import React, { ChangeEvent, memo } from 'react';
import { FormattedMessage, useIntl } from 'src/migration/react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CrossIcon } from 'src/modules/common/icons/CrossIcon';
import { PlusIcon } from 'src/modules/common/icons/PlusIcon';
import { SearchIcon } from 'src/modules/common/icons/SearchIcon';

type Props = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  handleAddUserOpen: () => void;
};

export const UserListActions = memo(({ searchQuery, setSearchQuery, handleAddUserOpen }: Props) => {
  const intl = useIntl();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim().toLowerCase();

    if (value.length > 100) {
      return;
    }

    setSearchQuery(value);
  };

  const handleSearchClear = (): void => {
    setSearchQuery('');
  };

  return (
    <div className="bp-user-list-actions">
      <div className="bp-user-list-actions__search-wrapper">
        <div className="bp-user-list-actions__search-icon">
          <SearchIcon/>
        </div>

        <input
          className="bp-user-list-actions__search"
          value={searchQuery}
          onChange={handleSearchChange}
        />

        {searchQuery && (
          <div
            role="button"
            className="bp-user-list-actions__clear-icon"
            onClick={handleSearchClear}
            onKeyDown={() => null}
            tabIndex={0}
            aria-label={intl.formatMessage({ id: 'admin/users/search/clear/label' })}
          >
            <CrossIcon/>
          </div>
        )}
      </div>

      <CustomButton size="md" onClick={handleAddUserOpen}>
        <FormattedMessage id="admin/users/list/header/addButton"/>

        <div className="bp-user-list-actions__icon">
          <PlusIcon/>
        </div>
      </CustomButton>
    </div>
  );
});
