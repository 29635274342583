import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { ORGANIZATION_CHANGE } from 'src/modules/organization/actions/OrganizationActions';
import { saveOrganizationForm } from 'src/modules/organization/sagas/utils/saveOrganizationForm';
import { syncUserOrganization } from 'src/modules/organization/sagas/utils/syncUserOrganization';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* organizationChangeSaga(
  action: GetRequestActionType<typeof ORGANIZATION_CHANGE>,
): SagaIterator<void> {
  const form = action.data;
  const values = form.values.get(form.currentState);

  try {
    yield* call(logDebug, 'Saving organization settings...');
    yield* put(ORGANIZATION_CHANGE.pending());
    yield* call(formSubmitStart, form);

    yield* delay(500);
    yield* call(updateUserLatestActivity);

    const organization = yield* call(saveOrganizationForm, values);
    yield* call(formSubmitSuccess, form);
    yield* call(syncUserOrganization, organization);

    yield* call(logDebug, 'Saving organization settings... done', values);
    yield* put(ORGANIZATION_CHANGE.success());
  } catch (error) {
    const wrapped = new RuntimeError('Could not save organization', { values }, NativeError.wrap(error));

    yield* call(logError, 'Saving organization settings... error', error);
    yield* put(ORGANIZATION_CHANGE.failure(wrapped));

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(formSubmitFailure, form, {});
  }
}
