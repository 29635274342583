import './SchemaInputLayer.scss';
import { AddAlt } from '@carbon/icons-react';
import clsx from 'clsx';
import React from 'react';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { memoize } from 'src/modules/common/utils/decorator';
import { SCHEMA_CONTAINER_SIZE } from 'src/modules/schema/constants/schema';
import { SchemaAssignment } from 'src/modules/schema/types/SchemaAssignment';
import { SchemaContainer } from 'src/modules/schema/types/SchemaContainer';

type Props = {
  readonly assignments: ReadonlyArray<SchemaAssignment>;
  readonly onSelect: (assignment: SchemaAssignment) => void;
};

export class SchemaInputLayer extends React.PureComponent<Props> {
  public render(): React.ReactElement {
    const { assignments } = this.props;

    return (
      <div className="bp-schema-input-layer">
        {assignments.map((assignment) => (
          <CustomButton
            className="bp-schema-input-layer__button"
            key={assignment.container.id}
            type="button"
            kind="ghost"
            onClick={this.getSelectHandler(assignment)}
            style={this.getButtonStyles(assignment.container)}
            data-container-id={assignment.container.id}
          >
            <AddAlt
              className={clsx(
                'bp-schema-input-layer__icon',
                assignment.selection ? 'bp-schema-input-layer__icon--hidden' : null,
              )}
              size={48}
            />
          </CustomButton>
        ))}
      </div>
    );
  }

  @memoize((assignment) => assignment)
  private getSelectHandler(assignment: SchemaAssignment): () => void {
    return () => {
      const { onSelect } = this.props;
      onSelect(assignment);
    };
  }

  private getButtonStyles(container: SchemaContainer): React.CSSProperties {
    return {
      left: container.position.x,
      top: container.position.y,
      width: SCHEMA_CONTAINER_SIZE.x,
      height: SCHEMA_CONTAINER_SIZE.y,
    };
  }
}
