import { DEFAULT_SCHEMA_SELECTION } from 'src/modules/schema/constants/schema';
import { schemaSelectionReducer } from 'src/modules/schema/reducers/schemaSelectionReducer';
import { SchemaState } from 'src/modules/schema/types/SchemaState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: SchemaState = {
  selection: DEFAULT_SCHEMA_SELECTION,
};

export const schemaReducer = chainReducers(defaultState, [
  schemaSelectionReducer,
]);
