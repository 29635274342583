import { useForm } from '@form-ts/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { BASEDATA_CHANGE } from 'src/modules/basedata/actions/BasedataActions';
import { BASEDATA_FORM_SCHEMA } from 'src/modules/basedata/constants/schema';
import { getBasedataFormData } from 'src/modules/basedata/selectors/getBasedataFormData';
import { BasedataFormData } from 'src/modules/basedata/types/BasedataFormData';
import { BasedataFormView } from 'src/modules/basedata/views/BasedataFormView';
import { ProjectToolbar } from 'src/modules/common/components/ProjectToolbar';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const BasedataPage = (): React.ReactElement => {
  const intl = useIntl();
  const project = useSelector(getProjectDetails);
  const formData = useSelector(getBasedataFormData);

  const saveForm = useAction(BASEDATA_CHANGE.trigger);
  const shareProject = useBoundAction(PROJECT_VIEW_SHARE.trigger, project);

  const form = useForm<BasedataFormData, FormError>('project.basedata', {
    reinitialize: false,
    initialValues: formData,
  });
  useFormValidator(form, BASEDATA_FORM_SCHEMA);

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      saveForm(nextState.values);
    }
  }), [form, saveForm]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'basedata/pageTitle' })}
        </title>
      </Helmet>

      <AppLayout>
        <ProjectToolbar
          project={project}
          onShare={shareProject}
        />
        <BasedataFormView
          projectId={project.id}
          form={form}
        />
      </AppLayout>

      <ShareProject/>
    </>
  );
};
