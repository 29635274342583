import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';

export const SCHEMA_AREA_MAP: <T>(codec: C.Codec<unknown, Json, T>) => C.Codec<unknown, Json, SchemaAreaMap<T>> =
  (codec) => C.struct({
    [SchemaArea.HEATING]: C.nullable(codec),
    [SchemaArea.COOLING]: C.nullable(codec),
    [SchemaArea.VENTILATION]: C.nullable(codec),
  });
