import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { Project } from 'src/modules/common/types/Project';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';
import { call } from 'typed-redux-saga';

export function* removeUserFromSharedProject(sharedUser: SharedUser, project: Project): SagaIterator<void> {
  try {
    yield* call(updateUserLatestActivity);

    yield* call(
      makeRestRequest,
      'DELETE',
      `/projects/${encodeURIComponent(project.id)}/users/${encodeURIComponent(sharedUser.id)}`,
    );
  } catch (error) {
    throw new RuntimeError(
      'Could not delete user from sharing project',
      { sharedUser, project },
      NativeError.wrap(error),
    );
  }
}
