import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { ORGANIZATION_USER } from 'src/modules/users/codecs/OrganizationUser';
import { User } from 'src/modules/users/types/User';
import { call, select } from 'typed-redux-saga';

export function* updateUser(user: User): SagaIterator<User> {
  const authUser = yield* select(getLoggedInUser);

  try {
    yield* call(updateUserLatestActivity);

    const { content } = yield* call(
      makeRestRequest,
      'PUT',
      `/organizations/${encodeURIComponent(authUser.organization.id)}/users/${encodeURIComponent(user.id)}`,
      {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
      },
    );
    return yield* call(decodeOrThrow, ORGANIZATION_USER, content);
  } catch (error) {
    throw new RuntimeError('Could not update user', { user }, NativeError.wrap(error));
  }
}
