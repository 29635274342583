import './OrganizationSettings.scss';
import { CountryCode } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import { constNull } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { TileCard } from 'src/modules/common/components/TileCard';
import { useCountryList } from 'src/modules/common/hooks/useCountryList';
import { OrganizationOffice } from 'src/modules/common/types/OrganizationOffice';
import { AssetField } from 'src/modules/form/components/AssetField';
import { AutocompleteField } from 'src/modules/form/components/AutocompleteField';
import { TextField } from 'src/modules/form/components/TextField';
import { ToggleField } from 'src/modules/form/components/ToggleField';
import { FormError } from 'src/modules/form/types/FormError';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';

type Props = {
  readonly office: OrganizationOffice;
  readonly form: FormController<OrganizationFormData, FormError>;
  readonly onSubmit: () => void;
};

export const OrganizationSettings = ({ office, form, onSubmit }: Props): React.ReactElement => {
  const countryCodes = useCountryList();

  const isSubmitting = useFormWatch(form, form.submitting.get);
  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  }, [onSubmit]);

  // NOTE: use fake form to re-use form components
  const officeForm = useForm<OrganizationOffice, FormError>('organization.office', {
    reinitialize: true,
    initialValues: office,
  });

  return (
    <form className="bp-organization-settings" onSubmit={handleSubmit}>
      <div className="bp-organization-settings__content">
        <div className="bp-organization-settings__contacts">
          <TileCard title={<FormattedMessage id="organization/settings/contacts/title"/>}>
            <div className="bp-organization-settings__row">
              <ToggleField
                field={form.field.at('useOfficeInfo')}
                label={<FormattedMessage id="organization/settings/form/sameAsOffice"/>}
                disabled={isSubmitting}
              />
            </div>
            <div className="bp-organization-settings__row">
              <TextField
                size="lg"
                field={form.field.at('name')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/name"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row bp-organization-settings__row--2-1">
              <TextField
                size="lg"
                field={form.field.at('address').at('street')}
                disabled={isSubmitting}
                labelText={<FormattedMessage id="organization/settings/form/street"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={form.field.at('address').at('city')}
                disabled={isSubmitting}
                labelText={<FormattedMessage id="organization/settings/form/city"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row bp-organization-settings__row--1-1-1">
              <AutocompleteField
                size="lg"
                direction="top"
                field={form.field.at('address').at('country')}
                disabled={isSubmitting}
                titleText={<FormattedMessage id="organization/settings/form/country"/>}
                options={countryCodes}
                itemToElement={renderCountryOption}
              />

              <TextField
                size="lg"
                field={form.field.at('address').at('zip')}
                disabled={isSubmitting}
                labelText={<FormattedMessage id="organization/settings/form/zip"/>}
                autoComplete="off"
                maxLength={10}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={form.field.at('contact').at('phone')}
                disabled={isSubmitting}
                labelText={<FormattedMessage id="organization/settings/form/phone"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row bp-organization-settings__row--1-1">
              <TextField
                size="lg"
                field={form.field.at('contact').at('email')}
                disabled={isSubmitting}
                labelText={<FormattedMessage id="organization/settings/form/email"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={form.field.at('contact').at('website')}
                disabled={isSubmitting}
                labelText={<FormattedMessage id="organization/settings/form/website"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
          </TileCard>
        </div>
        <div className="bp-organization-settings__office">
          <TileCard title={<FormattedMessage id="organization/settings/office/title"/>}>
            <div className="bp-organization-settings__row">
              <TextField
                size="lg"
                field={officeForm.field.at('name')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/name"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row bp-organization-settings__row--2-1">
              <TextField
                size="lg"
                field={officeForm.field.at('address').at('street')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/street"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={officeForm.field.at('address').at('city')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/city"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row bp-organization-settings__row--1-1-1">
              <AutocompleteField
                size="lg"
                direction="top"
                field={officeForm.field.at('address').at('country')}
                disabled={true}
                titleText={<FormattedMessage id="organization/settings/form/country"/>}
                options={countryCodes}
                itemToElement={renderCountryOption}
              />

              <TextField
                size="lg"
                field={officeForm.field.at('address').at('zip')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/zip"/>}
                autoComplete="off"
                maxLength={10}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={officeForm.field.at('contact').at('phone')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/phone"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row bp-organization-settings__row--1-1">
              <TextField
                size="lg"
                field={officeForm.field.at('contact').at('email')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/email"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={officeForm.field.at('contact').at('website')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/website"/>}
                autoComplete="off"
                maxLength={100}
                renderHint={constNull}
              />
            </div>
          </TileCard>
        </div>
        <div className="bp-organization-settings__logo">
          <TileCard title={<FormattedMessage id="organization/settings/logo/title"/>}>
            <FormattedMessage id="organization/settings/logo/label"/>

            <AssetField
              field={form.field.at('logo')}
              disabled={isSubmitting}
            />
          </TileCard>
        </div>
      </div>
    </form>
  );
};

function renderCountryOption(option: CountryCode | null): React.ReactNode {
  return option ? <FormattedMessage id={`common/country/name/${option}`}/> : null;
}
