import './AutocompleteField.scss';
import { ComboBox, OnChangeData } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutocompleteOption } from 'src/modules/common/types/AutocompleteOption';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData, TValue> = {
  readonly wrapperClass?: string;
  readonly field: FieldPath<TData, FormError, TValue>;
  readonly options: ReadonlyArray<AutocompleteOption<TValue>>;
  readonly label?: string;
  readonly outlined?: boolean;
  readonly size?: 'sm' | 'md' | 'lg';
  readonly theme?: 'gray' | 'white';
  readonly direction?: 'top' | 'bottom';
  readonly titleText: ReactNode;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly itemToString?: (item: AutocompleteOption<TValue> | null) => string;
  readonly itemToElement: (item: TValue) => React.ReactNode;
  readonly renderError?: (error: FormError) => React.ReactNode;
};

export const AutocompleteField = <TData, TValue>({
  wrapperClass,
  field,
  options,
  label,
  size,
  theme = 'gray',
  outlined,
  direction,
  titleText,
  required,
  itemToString,
  itemToElement,
  renderError = renderErrorDefault,
  ...rest
}: Props<TData, TValue>): React.ReactElement => {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback(({ selectedItem }: OnChangeData<AutocompleteOption<TValue> | undefined>) => {
    if (!selectedItem?.value) {
      return;
    }

    field.form.change(pipe(
      field.form.currentState,
      field.value.set(selectedItem.value),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  const itemToElementHandler = useCallback(
    (option: AutocompleteOption<TValue>) => itemToElement(option.value),
    [itemToElement],
  );

  const selectedItem = React.useMemo(() => {
    const found = options.find((option) => option.value === value);
    return found ?? null;
  }, [value, options]);

  return (
    <ComboBox<AutocompleteOption<TValue>>
      {...rest}
      className={clsx(
        'bp-autocomplete-field',
        wrapperClass,
        `bp-autocomplete-field--${size}`,
        `bp-autocomplete-field--${theme}`,
        {
          'bp-autocomplete-field--outlined': outlined,
          'bp-autocomplete-field--invalid': invalid,
          'bp-autocomplete-field--required': required,
        },
      )}
      direction={direction}
      titleText={titleText}
      id={`${field.form.name}.${field.path}`}
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={invalid}
      invalidText={error && invalid ? renderError(error) : null}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      items={options as Array<AutocompleteOption<TValue>>}
      itemToString={itemToString}
      itemToElement={itemToElementHandler}
      selectedItem={selectedItem}
    />
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
);
