import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { SCHEMA_TEMPLATE_SELECTION } from 'src/modules/common/constants/localStorageKeys';
import { SCHEMA_AREA_MAP } from 'src/modules/schema/codecs/SchemaAreaMap';
import { SCHEMA_SELECTION } from 'src/modules/schema/codecs/SchemaSelection';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';
import { SchemaSelection } from 'src/modules/schema/types/SchemaSelection';
import { storageSet } from 'src/sagas/utils/storage';
import { call } from 'typed-redux-saga';

export function* saveSchemaSelection(
  selection: SchemaAreaMap<SchemaSelection>,
): SagaIterator<void> {
  try {
    const stored = yield* call(STORAGE_CODEC.encode, selection);
    yield* call(storageSet, SCHEMA_TEMPLATE_SELECTION, stored);
  } catch (error) {
    throw new RuntimeError('Could not save schema selection', { selection }, NativeError.wrap(error));
  }
}

const STORAGE_CODEC = SCHEMA_AREA_MAP(SCHEMA_SELECTION);
