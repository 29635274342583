import { Language } from 'src/types/common/Language';

export const TERMS_AND_PRIVACY_LINK_MAP: Readonly<Record<Language, string>> = {
  'de-CH': 'https://www.belimo.com/ch/de_CH/privacy',
  'en-US': 'https://www.belimo.com/ch/en_US/privacy',
  'fr-FR': 'https://www.belimo.com/fr/fr_FR/privacy',
  'hu-HU': 'https://www.belimo.com/at/hu_HU/privacy',
  'es-ES': 'https://www.belimo.com/es/es_ES/privacy',
  'sv-SE': 'https://www.belimo.com/se/sv_SE/privacy',
  'zh-CN': 'https://www.belimo.com.cn/cn/zh_CN/privacy',
  'it-IT': 'https://www.belimo.com/it/it_IT/privacy',
  'sr-RS': 'https://www.belimo.com/at/sr_RS/privacy',
  'mk-MK': 'https://www.belimo.com/at/mk_MK/privacy',
};

export const IMPRINT_LINK_MAP: Readonly<Record<Language, string>> = {
  'de-CH': 'https://www.belimo.com/ch/de_CH/imprint',
  'en-US': 'https://www.belimo.com/ch/en_US/imprint',
  'fr-FR': 'https://www.belimo.com/fr/fr_FR/imprint',
  'hu-HU': 'https://www.belimo.com/at/hu_HU/imprint',
  'es-ES': 'https://www.belimo.com/es/es_ES/imprint',
  'sv-SE': 'https://www.belimo.com/se/sv_SE/imprint',
  'zh-CN': 'https://www.belimo.com.cn/cn/zh_CN/imprint',
  'it-IT': 'https://www.belimo.com/it/it_IT/imprint',
  'sr-RS': 'https://www.belimo.com/at/sr_RS/imprint',
  'mk-MK': 'https://www.belimo.com/at/mk_MK/imprint',
};
