import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { array } from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { Project } from 'src/modules/common/types/Project';
import { SHARED_USER } from 'src/modules/sharing-projects/codecs/SharedUser';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';
import { call } from 'typed-redux-saga';

export function* loadSharedProjectUsers(project: Project): SagaIterator<Array<SharedUser>> {
  try {
    yield* call(updateUserLatestActivity);

    const { content } = yield* call(
      makeRestRequest,
      'GET',
      `/projects/${encodeURIComponent(project.id)}/users`,
    );

    return yield* call(decodeOrThrow, array(SHARED_USER), content);
  } catch (error) {
    throw new RuntimeError(
      'Could not load list of project users (shared)',
      { project },
      NativeError.wrap(error),
    );
  }
}
