import './FileUpload.scss';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { FILE_MAX_SIZE_FILED_MB } from 'src/modules/common/constants/fileField';
import { UploadIcon } from 'src/modules/common/icons/UploadIcon';

const DROPZONE_SIZE_ERROR = 'file-too-large';

type Props = {
  readonly id?: string;
  readonly name?: string;
  readonly disabled: boolean;
  readonly children: React.ReactNode;

  readonly onTouch: () => void;
  readonly onChange: (file: File) => void;
};

export const FileUpload = ({
  id,
  name,
  onTouch,
  onChange,
  disabled,
  children,
}: Props): React.ReactElement => {
  const [isSizeError, setSizeError] = useState(false);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      onChange(file);
    }
  }, [onChange]);

  const handleReject = (rejectData: FileRejection[]): void => {
    const rejection = rejectData[0];

    const dropzoneSizeError = rejection.errors.find((error) => error.code === DROPZONE_SIZE_ERROR);
    if (dropzoneSizeError) {
      setSizeError(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    onFileDialogOpen: onTouch,
    onDropRejected: handleReject,
    disabled: disabled,
    maxSize: FILE_MAX_SIZE_FILED_MB * 1024 * 1024, // 10 MB in bytes
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });

  useEffect(() => {
    if (isSizeError) {
      const timer = setTimeout(() => {
        setSizeError(false);
      }, 3000);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [isSizeError]);

  return (
    <div
      {...getRootProps()}
      className={clsx(
        'bp-file-upload',
        disabled ? 'bp-file-upload--disabled' : null,
      )}
      data-testid="file-upload"
    >
      <input
        id={id}
        name={name}
        {...getInputProps()}
      />

      <div className="bp-file-upload__drop-area">
        <UploadIcon/>

        {isSizeError ? (
          <DropdownWarning/>
        ) : children}
      </div>
    </div>
  );
};

const DropdownWarning = (): React.ReactElement => (
  <p className="bp-file-upload__warning">
    <FormattedMessage id="form/file/field/error/size" values={{ maxSize: FILE_MAX_SIZE_FILED_MB }}/>
  </p>
);
