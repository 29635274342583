import { Locale } from 'src/types/common/Locale';

export const SUPPORTED_LOCALES: ReadonlyMap<string, Locale> = new Map([
  ['en', 'en-us'],
  ['de', 'de-de'],
  ['fr', 'fr-fr'],
  ['hu', 'hu-hu'],
  ['es', 'es-es'],
  ['sv', 'sv-se'],
  ['zh', 'zh-cn'],
  ['it', 'it-it'],
  ['sr', 'sr-rs'],
  ['mk', 'mk-mk'],

  ['en-au', 'en-au'],
  ['en-ca', 'en-ca'],
  ['en-gb', 'en-gb'],
  ['en-us', 'en-us'],

  ['de-at', 'de-at'],
  ['de-de', 'de-de'],
  ['de-li', 'de-li'],
  ['de-lu', 'de-lu'],
  ['de-ch', 'de-ch'],

  ['fr-be', 'fr-be'],
  ['fr-ca', 'fr-ca'],
  ['fr-fr', 'fr-fr'],
  ['fr-lu', 'fr-lu'],
  ['fr-ch', 'fr-ch'],

  ['hu-hu', 'hu-hu'],

  ['es-es', 'es-es'],

  ['sv-se', 'sv-se'],

  ['zh-cn', 'zh-cn'],

  ['it-it', 'it-it'],

  ['sr-rs', 'sr-rs'],

  ['mk-mk', 'mk-mk'],
]);
