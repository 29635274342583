import { Lens } from 'monocle-ts/Lens';

const NON_NULL_LENS = {
  get: <T>(value: T): T => value,
  set: <T>(value: T | null) => (state: T): T => value ?? state,
};

export function ensureNonNull<T>(): Lens<T, T | null> {
  return NON_NULL_LENS;
}
