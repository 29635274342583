import { AreaUnit, BuildingType, CurrencyCode, EnergySource } from '@belimo-retrofit-portal/logic';

export const ENERGY_SOURCE_HEATING_OPTIONS: ReadonlyArray<EnergySource> = [
  EnergySource.ELECTRICITY,
  EnergySource.HEATING_DISTRICT,
  EnergySource.HEATING_OIL,
  EnergySource.GAS,
  EnergySource.HEATING_OTHER,
];

export const ENERGY_SOURCE_COOLING_OPTIONS: ReadonlyArray<EnergySource> = [
  EnergySource.ELECTRICITY,
  EnergySource.COOLING_DISTRICT,
  EnergySource.GAS,
  EnergySource.COOLING_OTHER,
];

export const BUILDING_TYPE_OPTIONS: ReadonlyArray<BuildingType> = [
  BuildingType.OFFICE,
  BuildingType.DATA_CENTER,
  BuildingType.EDUCATION_ELEMENTARY,
  BuildingType.EDUCATION_HIGHER,
  BuildingType.EDUCATION_OTHER,
  BuildingType.ENTERTAINMENT_LEISURE,
  BuildingType.GOVERNMENT,
  BuildingType.HEALTH,
  BuildingType.HOSPITAL,
  BuildingType.HOTEL,
  BuildingType.LIFE_SCIENCE,
  BuildingType.RESTAURANT,
  BuildingType.RETAIL,
  BuildingType.WHOLESALE_RETAIL_TRADE,
];

export const CURRENCY_CODE_OPTIONS: ReadonlyArray<CurrencyCode> = [
  'EUR',
  'CHF',
  'USD',
  'GBP',
  'HKD',
  'CNY',
  'INR',
];

export const UNIT_VERSION_OPTIONS: ReadonlyArray<AreaUnit> = [
  AreaUnit.SQM,
  AreaUnit.SQFT,
];
