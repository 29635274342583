import { createSelector } from 'reselect';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';

export const getOrganizationFormData = createSelector([
  getLoggedInUser,
], (user): OrganizationFormData => ({
  name: user.organization.name,
  logo: user.organization.logo,

  address: user.organization.address,
  contact: user.organization.contact,

  useOfficeInfo: user.organization.useOfficeInfo,
}));
