import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { call } from 'typed-redux-saga';

export function* updateUserLatestActivity(): SagaIterator<void> {
  try {
    yield* call(
      makeRestRequest,
      'PUT',
      '/account/update-latest-activity',
    );
  } catch (error) {
    throw new RuntimeError('Could not update user`s latest activity', {}, NativeError.wrap(error));
  }
}
