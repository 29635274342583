import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { SCHEMA_TEMPLATE_SELECTION } from 'src/modules/common/constants/localStorageKeys';
import { SCHEMA_AREA_MAP } from 'src/modules/schema/codecs/SchemaAreaMap';
import { SCHEMA_SELECTION } from 'src/modules/schema/codecs/SchemaSelection';
import { DEFAULT_SCHEMA_SELECTION } from 'src/modules/schema/constants/schema';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';
import { SchemaSelection } from 'src/modules/schema/types/SchemaSelection';
import { storageGet } from 'src/sagas/utils/storage';
import { call } from 'typed-redux-saga';

export function* loadSchemaSelection(): SagaIterator<SchemaAreaMap<SchemaSelection>> {
  try {
    const stored = yield* call(storageGet, SCHEMA_TEMPLATE_SELECTION);
    return yield* call(decodeOrGet, STORAGE_CODEC, stored, DEFAULT_SCHEMA_SELECTION);
  } catch (error) {
    throw new RuntimeError('Could not load schema selection', {}, NativeError.wrap(error));
  }
}

const STORAGE_CODEC = SCHEMA_AREA_MAP(SCHEMA_SELECTION);
