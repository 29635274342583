import { pipe } from 'fp-ts/function';
import { mapWithIndex } from 'fp-ts/ReadonlyRecord';
import { createSelector } from 'reselect';
import { getReportFormData } from 'src/modules/report/selectors/getReportFormData';
import { ReportGeneratedSchema } from 'src/modules/report/types/ReportGenerated';
import { getSchemaFormData } from 'src/modules/schema/selectors/getSchemaFormData';

export const getReportGeneratedSchema = createSelector([
  getReportFormData,
  getSchemaFormData,
], (report, schema): ReportGeneratedSchema => pipe(
  schema,
  mapWithIndex((area, selection) => {
    if (selection === null) {
      return null;
    }

    return {
      schema: selection.schema,
      comment: selection.comment,
      actual: selection.actual,
      future: selection.future.find((version) => version.number === report.schema[area].futureVersion) ?? null,
      matrix: selection.matrix,
    };
  }),
));
