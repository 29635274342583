import './EvaluationResultsChartsGraph.scss';
import { EvaluationResultsChartsSeria } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { FormattedNumber } from 'src/modules/common/components/FormattedNumber';
import { EvaluationResultsChartsXTick } from 'src/modules/evaluation/views/EvaluationResultsChartsXTick';

type Props = {
  readonly data: EvaluationResultsChartsSeria | null;
  readonly labels: ReadonlyRecord<'year' | 'exclCO2' | 'inclCO2', React.ReactNode>;
  readonly xAxisLabel?: string;
  readonly yAxisLabel?: string;
  readonly interactive: boolean;
  readonly areCostSavingsExcludedNegative: boolean;
  readonly isInvestmentEmpty: boolean;
};

export const EvaluationResultsChartsGraphArea = memo((
  { data, labels, xAxisLabel, yAxisLabel, interactive, areCostSavingsExcludedNegative, isInvestmentEmpty }: Props,
) => {
  const chartData = useMemo(() => {
    if (data === null) {
      return [];
    }

    return data.map((point) => ({
      year: point.year.toNumber(),
      exclCO2: point.value.exclCO2.mul(100).toDecimalPlaces(1).toNumber(),
      inclCO2: point.value.inclCO2.mul(100).toDecimalPlaces(1).toNumber(),
    }));
  }, [data]);

  const [isChartReady, setChartReady] = useState(chartData.length === 0);

  const yStep = useMemo(() => (data ? getYStep(data) : 20), [data]);
  const yDomain = useMemo(() => {
    if (data === null) {
      return [-80, +20];
    }

    const roundMin = (v: Decimal): Decimal => v.div(yStep).sub(0.25).toDecimalPlaces(0, Decimal.ROUND_FLOOR).mul(yStep);
    const roundMax = (v: Decimal): Decimal => v.div(yStep).add(0.25).toDecimalPlaces(0, Decimal.ROUND_CEIL).mul(yStep);

    const domainMin = Math.min(
      Number.POSITIVE_INFINITY,
      ...data.map((it) => roundMin(it.value.inclCO2.mul(100)).toNumber()),
      ...data.map((it) => roundMin(it.value.exclCO2.mul(100)).toNumber()),
    );
    const domainMax = Math.max(
      Number.NEGATIVE_INFINITY,
      ...data.map((it) => roundMax(it.value.inclCO2.mul(100)).toNumber()),
      ...data.map((it) => roundMax(it.value.exclCO2.mul(100)).toNumber()),
    );

    return [
      domainMin,
      domainMax,
    ];
  }, [data, yStep]);

  const yTicks = useMemo(() => {
    const [min, max] = yDomain;
    return Array.from({ length: (max - min) / yStep + 1 }, (_, i) => min + i * yStep);
  }, [yStep, yDomain]);

  const renderTooltip = useCallback((props: TooltipProps<ValueType, NameType>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { label } = props;

    const value = chartData.find((it) => it.year === label);
    if (value === undefined) {
      return null;
    }

    return (
      <div className="bp-evaluation-results-charts-graph__tooltip">
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: 'none' }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            {labels.year}
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <FormattedNumber
              value={value.year}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: COLORS.exclCO2 }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            {labels.exclCO2}
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <FormattedNumber
              value={value.exclCO2}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: COLORS.inclCO2 }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            {labels.inclCO2}
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <FormattedNumber
              value={value.inclCO2}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          </div>
        </div>
      </div>
    );
  }, [chartData, labels]);

  const renderLegend = useMemo(() => (
    <div className="bp-evaluation-results-charts-graph__legend-wrapper">
      <div className="bp-evaluation-results-charts-graph__legend">
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.exclCO2 }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            {labels.exclCO2}
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.inclCO2 }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            {labels.inclCO2}
          </div>
        </div>
      </div>
    </div>
  ), [labels]);

  const renderNotification = useMemo(() => {
    if (areCostSavingsExcludedNegative) {
      return (
        <div className="bp-evaluation-results-charts-graph__empty">
          <FormattedMessage id="evaluation/charts/savings/negative"/>
        </div>
      );
    }

    if (isInvestmentEmpty) {
      return (
        <div className="bp-evaluation-results-charts-graph__empty">
          <FormattedMessage id="evaluation/charts/savings/empty"/>
        </div>
      );
    }

    return null;
  }, [areCostSavingsExcludedNegative, isInvestmentEmpty]);

  return (
    <div className="bp-evaluation-results-charts-graph__graph-wrapper" data-ready={isChartReady} data-type="area">
      <div className="bp-evaluation-results-charts-graph__graph">
        {renderNotification}

        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <CartesianGrid
              stroke="#C3C3C3"
              strokeWidth={1}
              strokeDasharray="3 3"
            />

            <XAxis
              dataKey="year"
              domain={[0, 20]}
              ticks={chartData.map((it) => it.year)}
              tick={EvaluationResultsChartsXTick}
              fontSize={12}
            />

            <YAxis
              tickFormatter={formatYTick}
              domain={yDomain}
              ticks={yTicks}
              fontSize={12}
            />

            {interactive && (
              <Tooltip
                isAnimationActive={false}
                content={renderTooltip}
                cursor={{
                  stroke: '#2C2B2B',
                  strokeWidth: 1,
                  strokeDasharray: '3 3',
                }}
              />
            )}

            <Area
              onAnimationEnd={() => setChartReady(true)}
              dataKey="exclCO2"
              fill={COLORS.exclCO2}
              fillOpacity={0.25}
              stroke={COLORS.exclCO2}
              strokeWidth={2}
              strokeOpacity={1}
              baseValue="dataMin"
              dot={{
                fill: COLORS.exclCO2,
                fillOpacity: 1,
                stroke: COLORS.exclCO2,
                strokeWidth: 4,
              }}
              activeDot={{
                fill: COLORS.exclCO2,
                fillOpacity: 1,
                stroke: COLORS.exclCO2,
                strokeWidth: 5,
              }}
            />

            <Area
              onAnimationEnd={() => setChartReady(true)}
              dataKey="inclCO2"
              fill={COLORS.inclCO2}
              fillOpacity={0.25}
              stroke={COLORS.inclCO2}
              strokeWidth={2}
              strokeOpacity={1}
              baseValue="dataMin"
              dot={{
                fill: COLORS.inclCO2,
                fillOpacity: 1,
                stroke: COLORS.inclCO2,
                strokeWidth: 4,
              }}
              activeDot={{
                fill: COLORS.inclCO2,
                fillOpacity: 1,
                stroke: COLORS.inclCO2,
                strokeWidth: 5,
              }}
            />
          </AreaChart>
        </ResponsiveContainer>

        {renderLegend}

        <div className="bp-evaluation-results-charts-graph__y-label">
          <p className="bp-evaluation-results-charts-graph__y-label-text">
            {yAxisLabel}
          </p>
        </div>

        <div className="bp-evaluation-results-charts-graph__x-label">
          <p className="bp-evaluation-results-charts-graph__x-label-text">
            {xAxisLabel}
          </p>
        </div>
      </div>
    </div>
  );
});

const COLORS = {
  exclCO2: '#375F80',
  inclCO2: '#632E5B',
};

function getYStep(data: EvaluationResultsChartsSeria): number {
  const min = Math.min(
    Number.POSITIVE_INFINITY,
    ...data.map((it) => it.value.inclCO2.mul(100).toNumber()),
    ...data.map((it) => it.value.exclCO2.mul(100).toNumber()),
  );
  const max = Math.max(
    Number.NEGATIVE_INFINITY,
    ...data.map((it) => it.value.inclCO2.mul(100).toNumber()),
    ...data.map((it) => it.value.exclCO2.mul(100).toNumber()),
  );

  const diff = max - min;
  const pow = Math.max(1, 10 ** (Math.ceil(Math.log10(diff)) - 1));

  for (const x of [1, 2, 5]) {
    if (diff <= x * pow) {
      return x * pow * 0.1;
    }
  }

  return pow;
}

function formatYTick(value: unknown): string {
  if (typeof value !== 'number') {
    return '';
  }

  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000).toFixed(0)}G`;
  }
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(0)}M`;
  }
  if (value >= 1_000) {
    return `${(value / 1_000).toFixed(0)}k`;
  }

  return `${value.toFixed(0)}`;
}
