import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { ChangePasswordData } from 'src/modules/profile/types/ChangePasswordData';
import { call } from 'typed-redux-saga';

export function* changePassword(passwordData: ChangePasswordData): SagaIterator<void> {
  try {
    yield* call(updateUserLatestActivity);

    const body = { password: passwordData.password };
    yield* call(makeRestRequest, 'PUT', '/account/password', body);
  } catch (error) {
    throw new RuntimeError('Could not update password', { passwordData }, NativeError.wrap(error));
  }
}
