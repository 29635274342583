import './ProjectCreateModal.scss';
import { FormController } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useState } from 'react';
import { useAction } from 'src/hooks/useAction';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { FormError } from 'src/modules/form/types/FormError';
import { validate } from 'src/modules/form/utils/validate';
import { PROJECT_NEW_CREATE } from 'src/modules/project-new/actions/ProjectNewActions';
import { DEFAULT_BASIC_FORM_DATA, DEFAULT_BUILDING_FORM_DATA } from 'src/modules/project-new/constants/formData';
import { BASIC_SCHEMA, BUILDING_SCHEMA } from 'src/modules/project-new/constants/schema';
import { BasicFormData } from 'src/modules/project-new/types/BasicFormData';
import { BuildingFormData } from 'src/modules/project-new/types/BuildingFormData';
import { BasicInfoFormModal } from 'src/modules/project-new/views/BasicInfoFormModal';
import { BuildingInfoFormModal } from 'src/modules/project-new/views/BuildingInfoFormModal';

type Props = {
  readonly openCreationDialog: boolean;
  readonly onCancel: () => void;
};

export const ProjectCreateModal = ({ openCreationDialog, onCancel }: Props): React.ReactElement => {
  const [step, setStep] = useState<1 | 2>(1);

  const [basicInfoValues, setBasicInfoValues] = useState<BasicFormData>(DEFAULT_BASIC_FORM_DATA);
  const [buildingInfoValues, setBuildingInfoValues] = useState<BuildingFormData>(DEFAULT_BUILDING_FORM_DATA);

  const saveForm = useAction(PROJECT_NEW_CREATE.request);

  const handleNext = useCallback((form: FormController<BasicFormData, FormError>): void => {
    const values = form.values.get(form.currentState);
    const errors = validate(values, BASIC_SCHEMA);

    if (Object.keys(errors).length !== 0) {
      form.change(
        pipe(
          form.currentState,
          form.errors.set(errors),
          form.submitted.set(true),
          form.submitting.set(false),
        ),
      );
      return;
    }

    setBasicInfoValues(values);
    setStep(2);
  }, []);

  const handleBack = useCallback((form: FormController<BuildingFormData, FormError>): void => {
    const values = form.values.get(form.currentState);

    setBuildingInfoValues(values);
    setStep(1);
  }, []);

  const handleSubmit = useCallback((form: FormController<BuildingFormData, FormError>): void => {
    const values = form.values.get(form.currentState);
    const errors = validate(values, BUILDING_SCHEMA);

    if (Object.keys(errors).length !== 0) {
      form.change(
        pipe(
          form.currentState,
          form.errors.set(errors),
          form.submitted.set(true),
          form.submitting.set(false),
        ),
      );
      return;
    }

    saveForm({
      stepOne: basicInfoValues,
      stepTwo: form,
    });
  }, [saveForm, basicInfoValues]);

  return (
    <CustomModal
      open={openCreationDialog}
      onClose={onCancel}
      isShaded={false}
      size="md"
      shouldUnmount={true}
      preventCloseOnClickOutside={true}
    >
      {step === 1
        ? (
          <BasicInfoFormModal
            formData={basicInfoValues}
            onNext={handleNext}
            onClose={onCancel}
          />
        )
        : (
          <BuildingInfoFormModal
            formData={buildingInfoValues}
            onSubmit={handleSubmit}
            onBack={handleBack}
            onClose={onCancel}
          />
        )}
    </CustomModal>
  );
};
