import { Matrix, SchemaArea } from '@belimo-retrofit-portal/logic';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';
import { SchemaSelection } from 'src/modules/schema/types/SchemaSelection';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const SCHEMA_TEMPLATE_SELECT =
  createTrigger<ConfigSchema, void>('SCHEMA_TEMPLATE_SELECT');

export const SCHEMA_VERSION_CREATE =
  createTrigger<SchemaVersion, SchemaArea>('SCHEMA_VERSION_CREATE');

export const SCHEMA_VERSION_CHANGE =
  createTrigger<SchemaVersion, SchemaArea>('SCHEMA_VERSION_CHANGE');

export const SCHEMA_VERSION_REMOVE =
  createTrigger<SchemaVersion, SchemaArea>('SCHEMA_VERSION_REMOVE');

export const SCHEMA_COMMENT_CHANGE =
  createTrigger<string, SchemaArea>('SCHEMA_COMMENT_CHANGE');

export const SCHEMA_MATRIX_CHANGE = createTrigger<Matrix, SchemaArea>('SCHEMA_MATRIX_CHANGE');

export const SCHEMA_SELECTION_FETCH =
  createTrigger<SchemaAreaMap<SchemaSelection>>('SCHEMA_SELECTION_FETCH');

export const SCHEMA_SELECTION_CHANGE =
  createActions<SchemaSelection | null, SchemaAreaMap<SchemaSelection>, SchemaArea>('SCHEMA_SELECTION_CHANGE');
