import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { emulateTransitionDelay } from 'src/modules/routing/hooks/emulateTransitionDelay';
import { fetchUsersList } from 'src/modules/routing/hooks/resolvers/fetchUsersList';
import { resetUsersList } from 'src/modules/routing/hooks/resolvers/resetUsersList';
import { syncUser } from 'src/modules/routing/hooks/resolvers/syncUser';
import { createStateTransitionLoggingSaga } from 'src/modules/routing/hooks/stateTransitionLogging';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { composeSagas } from 'src/modules/routing/utils/composeSagas';

export const ROUTING_ADMIN_STATES: ReadonlyArray<RouteState> = [
  {
    url: '/admin/users',
    name: 'bp:admin:users',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
      fetchUsersList,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      syncUser,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
      fetchUsersList,
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      updateUserLatestActivity,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
      resetUsersList,
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
];
