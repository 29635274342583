import './ShareProjectModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomLoading } from 'src/modules/common/components/CustomLoading';
import { Future } from 'src/modules/common/types/Future';
import { Project } from 'src/modules/common/types/Project';
import { FormError } from 'src/modules/form/types/FormError';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';
import { ShareProjectForm } from 'src/modules/sharing-projects/types/ShareProjectForm';
import { ShareProjectShareForm } from 'src/modules/sharing-projects/views/ShareProjectShareForm';
import { ShareProjectUserList } from 'src/modules/sharing-projects/views/ShareProjectUserList';

type Props = {
  readonly project: Project;
  readonly users: Future<ReadonlyArray<SharedUser>>;

  readonly onSubmit: (form: FormController<ShareProjectForm, FormError>) => void;
  readonly onCancel: () => void;
  readonly onRemove: (user: SharedUser) => void;
};

export const ShareProjectModal = ({
  project,
  users,
  onSubmit,
  onCancel,
  onRemove,
}: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        labelClassName="bp-share-project-modal__label"
        titleClassName="bp-share-project-modal__title"
        label={<FormattedMessage id="shareProject/modal/label"/>}
        title={<FormattedMessage id="shareProject/modal/title"/>}
        buttonOnClick={onCancel}
      />

      <ModalBody className="bp-share-project-modal__body">
        {users.state === 'success' && (
          <div className="bp-share-project-modal__form">
            <ShareProjectShareForm
              project={project}
              users={users.value}
              onSubmit={onSubmit}
            />
          </div>
        )}

        {users.state === 'success' && (
          <div className="bp-share-project-modal__users">
            <ShareProjectUserList
              project={project}
              users={users.value}
              onRemove={onRemove}
            />
          </div>
        )}

        {users.state === 'pending' && (
          <div className="bp-share-project-modal__loading">
            <CustomLoading withOverlay={false}/>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          className="bp-share-project-modal__finish-button"
          onClick={onCancel}
        >
          <FormattedMessage id="shareProject/modal/action/finish"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};
