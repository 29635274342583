import './PasswordChangeForm.scss';
import { Stack } from '@carbon/react';
import { FormController } from '@form-ts/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { PasswordField } from 'src/modules/form/components/PasswordField';
import { FormError } from 'src/modules/form/types/FormError';
import { ChangePasswordData } from 'src/modules/profile/types/ChangePasswordData';

type Props = {
  form: FormController<ChangePasswordData, FormError>;
  onChange: () => void;
};

export const PasswordChangeForm = ({ form, onChange }: Props): React.ReactElement => {
  const intl = useIntl();

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    onChange();
  };

  return (
    <Stack as="form" className="bp-password-change-form" gap="1rem" onSubmit={handleSubmit} orientation="vertical">
      <Stack gap="0" orientation="vertical">
        <p className="bp-password-change-form__password-tip">
          {intl.formatMessage({ id: 'profile/form/section/changePassword/passwordTip1' })}
        </p>
        <p className="bp-password-change-form__password-tip">
          {intl.formatMessage({ id: 'profile/form/section/changePassword/passwordTip2' })}
        </p>
        <p className="bp-password-change-form__password-tip">
          {intl.formatMessage({ id: 'profile/form/section/changePassword/passwordTip3' })}
        </p>
      </Stack>
      {/* Accessibility: https://www.chromium.org/developers/design-documents/create-amazing-password-forms */}
      <input autoComplete="username" style={{ display: 'none' }}/>
      <PasswordField
        autoComplete="new-password"
        field={form.field.at('password')}
        labelText={intl.formatMessage({ id: 'profile/form/section/changePassword/newPassword/label' })}
        maxLength={30}
        minLength={8}
        renderError={(error) => intl.formatMessage({ id: error.message }, error.context)}
        size="md"
      />
      <PasswordField
        autoComplete="new-password"
        field={form.field.at('confirmation')}
        labelText={intl.formatMessage({ id: 'profile/form/section/changePassword/confirmPassword/label' })}
        maxLength={30}
        minLength={8}
        renderError={(error) => intl.formatMessage({ id: error.message }, error.context)}
        size="md"
      />
      <CustomButton
        size="md"
        className="bp-password-change-form__submit"
        kind="primary"
        onClick={onChange}
        type="submit"
      >
        {intl.formatMessage({ id: 'profile/form/section/changePassword/button/update' })}
      </CustomButton>
    </Stack>
  );
};
