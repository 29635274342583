import { Matrix } from '@belimo-retrofit-portal/logic';
import { Interpolation } from 'src/modules/common/types/Interpolation';

export const INTERPOLATION_MATRIX: Interpolation<Matrix> = {
  len: (x) => Math.hypot(
    x.a,
    x.b,
    x.c,
    x.d,
    x.e,
    x.f,
  ),
  add: (x, y) => ({
    a: x.a + y.a,
    b: x.b + y.b,
    c: x.c + y.c,
    d: x.d + y.d,
    e: x.e + y.e,
    f: x.f + y.f,
  }),
  sub: (x, y) => ({
    a: x.a - y.a,
    b: x.b - y.b,
    c: x.c - y.c,
    d: x.d - y.d,
    e: x.e - y.e,
    f: x.f - y.f,
  }),
  mul: (x, k) => ({
    a: x.a * k,
    b: x.b * k,
    c: x.c * k,
    d: x.d * k,
    e: x.e * k,
    f: x.f * k,
  }),
};
