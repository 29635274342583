import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { SCHEMA_SELECTION_CHANGE } from 'src/modules/schema/actions/SchemaActions';
import { saveSchemaSelection } from 'src/modules/schema/sagas/utils/saveSchemaSelection';
import { getSchemaSelection } from 'src/modules/schema/selectors/getSchemaSelection';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';
import { SchemaSelection } from 'src/modules/schema/types/SchemaSelection';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* schemaSelectionChangeSaga(
  action: GetRequestActionType<typeof SCHEMA_SELECTION_CHANGE>,
): SagaIterator<void> {
  const { data: schema, meta: area } = action;

  try {
    yield* call(logDebug, 'Saving schema selection...');
    yield* put(SCHEMA_SELECTION_CHANGE.pending(area));

    const previous = yield* select(getSchemaSelection);
    const modified: SchemaAreaMap<SchemaSelection> = {
      ...previous,
      [area]: schema,
    };

    yield* call(saveSchemaSelection, modified);

    yield* call(logDebug, 'Saving schema selection... done');
    yield* put(SCHEMA_SELECTION_CHANGE.success(modified, area));
  } catch (error) {
    const wrapped = new RuntimeError('Could not save schema selection', { schema }, NativeError.wrap(error));
    yield* call(sentryCatch, wrapped);

    yield* call(logError, 'Saving schema selection... error', { error });
    yield* put(SCHEMA_SELECTION_CHANGE.failure(wrapped, area));
  }
}
