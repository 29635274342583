import './MultipleSchemasScreen.scss';
import { Matrix, SchemaArea, Uuid } from '@belimo-retrofit-portal/logic';
import { Checkmark } from '@carbon/icons-react';
import { RadioButton, RadioButtonGroup } from '@carbon/react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BottomNavigationHelper } from 'src/modules/common/components/BottomNavigationHelper';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { CircleCheckFilledIcon } from 'src/modules/common/icons/CircleCheckFilledIcon';
import { CommentIcon } from 'src/modules/common/icons/CommentIcon';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { SCHEMA_AREA_MAP } from 'src/modules/schema/constants/schema';
import { SchemaAreaMap } from 'src/modules/schema/types/SchemaAreaMap';
import { SchemaFormData } from 'src/modules/schema/types/SchemaFormData';
import { SchemaSelection } from 'src/modules/schema/types/SchemaSelection';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';
import { SchemaEditScreen } from 'src/modules/schema/views/SchemaEditScreen';
import { SchemaSelectScreen } from 'src/modules/schema/views/SchemaSelectScreen';

type Props = {
  readonly projectId: Uuid;
  readonly schemas: SchemaAreaMap<SchemaFormData>;
  readonly templates: ReadonlyArray<ConfigSchema>;
  readonly selection: SchemaAreaMap<SchemaSelection>;
  readonly onSelectTemplate: (data: ConfigSchema) => void;
  readonly onChangeSelection: (data: SchemaSelection, meta: SchemaArea) => void;
  readonly onCreateVersion: (data: SchemaVersion, meta: SchemaArea) => void;
  readonly onChangeVersion: (data: SchemaVersion, meta: SchemaArea) => void;
  readonly onRemoveVersion: (data: SchemaVersion, meta: SchemaArea) => void;
  readonly onChangeComment: (data: string, meta: SchemaArea) => void;
  readonly onChangeMatrix: (data: Matrix, meta: SchemaArea) => void;
};

export const MultipleSchemasScreen = memo(({
  projectId,
  schemas,
  selection,
  templates,
  onSelectTemplate,
  onChangeSelection,
  onCreateVersion,
  onChangeVersion,
  onRemoveVersion,
  onChangeComment,
  onChangeMatrix,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const [activeTab, setActiveTab] = useState<SchemaArea>(SchemaArea.HEATING);
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(0);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);

  const currentAreaSchema = useMemo(() => schemas[activeTab] || null, [schemas, activeTab]);
  const currentAreaTemplates = useMemo(
    () => templates.filter((it) => SCHEMA_AREA_MAP[it.thermal_energy] === activeTab),
    [activeTab, templates],
  );
  const currentAreaSelection = useMemo(() => (
    currentAreaTemplates.find((item) => item.id === selection[activeTab]?.id) ??
    currentAreaTemplates[0] ??
    null
  ), [activeTab, currentAreaTemplates, selection]);

  const handleTabChange = useCallback((tab: unknown) => {
    setSelectedVersionIndex(0);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setActiveTab(tab as SchemaArea);
  }, []);

  const handleSelectionChange = useCallback((data: ConfigSchema) => {
    onChangeSelection({ id: data.id }, activeTab);
  }, [activeTab, onChangeSelection]);

  const handleSchemaCreate = useCallback(() => {
    if (currentAreaSelection) {
      onSelectTemplate(currentAreaSelection);
    }
  }, [currentAreaSelection, onSelectTemplate]);

  return (
    <div className="bp-multiple-schemas-screen">
      <div className="bp-multiple-schemas-screen__content">
        <div className="bp-multiple-schemas-screen__controls">
          <RadioButtonGroup
            className="bp-multiple-schemas-screen__radio-group"
            name={intl.formatMessage({ id: 'schemaPage/tab/group' })}
            valueSelected={activeTab}
            onChange={handleTabChange}
          >
            {SCHEMA_AREAS.map((area) => (
              <RadioButton
                key={area}
                value={area}
                id={area}
                labelText={(
                  <div className="bp-multiple-schemas-screen__tab-content">
                    <FormattedMessage id={`schemaPage/tab/${area}`}/>

                    {schemas[area]
                      ? (
                        <Checkmark color={CARBON_THEME.onSurfaceLightSecondary}/>
                      )
                      : null}
                  </div>
                )}
              />
            ))}
          </RadioButtonGroup>

          {currentAreaSchema && (
            <CustomButton
              className="bp-multiple-schemas-screen__button"
              kind="tertiary"
              size="md"
              onClick={() => setCommentModalOpen(true)}
            >
              <FormattedMessage id="schemaEdit/addComment"/>

              <div className="bp-multiple-schemas-screen__button-icon">
                <CommentIcon/>
              </div>

              <div className="bp-multiple-schemas-screen__comment-badge">
                {currentAreaSchema.comment.length > 0 && <CircleCheckFilledIcon/>}
              </div>
            </CustomButton>
          )}
        </div>

        {currentAreaSchema ? (
          <SchemaEditScreen
            handleCommentModalClose={() => setCommentModalOpen(false)}
            isCommentModalOpen={isCommentModalOpen}
            key={activeTab}
            schema={currentAreaSchema}
            selectedVersionIndex={selectedVersionIndex}
            onSelectVersionIndex={setSelectedVersionIndex}
            onChangeMatrix={(matrix) => onChangeMatrix(matrix, activeTab)}
            onCreateVersion={(version) => onCreateVersion(version, activeTab)}
            onRemoveVersion={(version) => onRemoveVersion(version, activeTab)}
            onChangeVersion={(version) => onChangeVersion(version, activeTab)}
            onChangeComment={(comment) => onChangeComment(comment, activeTab)}
          />
        ) : (
          <SchemaSelectScreen
            key={activeTab}
            area={activeTab}
            templates={currentAreaTemplates}
            selection={currentAreaSelection}
            onSelect={handleSelectionChange}
            onCreate={handleSchemaCreate}
          />
        )}

        <BottomNavigationHelper
          routeBackText={intl.formatMessage({ id: 'common/bottomNavigation/hvac' })}
          routeBackPath={`/project/${encodeURIComponent(projectId)}/hvac`}
          routeForthText={intl.formatMessage({ id: 'common/bottomNavigation/evaluation' })}
          routeForthPath={`/project/${encodeURIComponent(projectId)}/evaluation`}
        />
      </div>
    </div>
  );
});

const SCHEMA_AREAS = [
  SchemaArea.HEATING,
  SchemaArea.COOLING,
];
