import './ProjectListPage.scss';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { Project } from 'src/modules/common/types/Project';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import {
  PROJECT_LIST_DOWNLOAD,
  PROJECT_LIST_FILTER,
  PROJECT_LIST_EXPORT,
  PROJECT_LIST_IMPORT_CANCEL,
  PROJECT_LIST_IMPORT_COMPLETE,
  PROJECT_LIST_IMPORT_OPEN,
  PROJECT_LIST_IMPORT_SUBMIT,
  PROJECT_LIST_IMPORT_UPLOAD,
  PROJECT_LIST_REMOVE_CANCEL,
  PROJECT_LIST_REMOVE_CONFIRM,
  PROJECT_LIST_REMOVE_SUBMIT,
  PROJECT_LIST_UNFOLLOW_CONFIRM,
  PROJECT_LIST_UNFOLLOW_SUBMIT,
  PROJECT_LIST_UNFOLLOW_CANCEL,
  PROJECT_LIST_SHARE,
} from 'src/modules/project-list/actions/ProjectListActions';
import { getProjectListDownloading } from 'src/modules/project-list/selectors/getProjectListDownloading';
import { getProjectListExport } from 'src/modules/project-list/selectors/getProjectListExport';
import { getProjectListImport } from 'src/modules/project-list/selectors/getProjectListImport';
import { getProjectListProjects } from 'src/modules/project-list/selectors/getProjectListProjects';
import { getProjectListQuery } from 'src/modules/project-list/selectors/getProjectListQuery';
import { getProjectListRemoving } from 'src/modules/project-list/selectors/getProjectListRemoving';
import { getProjectListSlice } from 'src/modules/project-list/selectors/getProjectListSlice';
import { getProjectListUnfollowing } from 'src/modules/project-list/selectors/getProjectListUnfollowing';
import { ProjectListEmpty } from 'src/modules/project-list/views/ProjectListEmpty';
import { ProjectListImportModal } from 'src/modules/project-list/views/ProjectListImportModal';
import { ProjectListRemoveModal } from 'src/modules/project-list/views/ProjectListRemoveModal';
import { ProjectListToolbar } from 'src/modules/project-list/views/ProjectListToolbar';
import { ProjectListView } from 'src/modules/project-list/views/ProjectListView';
import { UnfollowProjectModal } from 'src/modules/project-list/views/UnfollowProjectModal';
import { ProjectCreateModal } from 'src/modules/project-new/views/ProjectCreateModal';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const ProjectListPage = (): React.ReactElement => {
  const intl = useIntl();
  const data = useSelector(getProjectListProjects);
  const slice = useSelector(getProjectListSlice);
  const query = useSelector(getProjectListQuery);
  const applyFilter = useAction(PROJECT_LIST_FILTER.trigger);
  const user = useSelector(getLoggedInUser);

  const [openCreationDialog, setOpenCreationDialog] = useState(false);

  const downloading = useSelector(getProjectListDownloading);
  const downloadReport = useAction(PROJECT_LIST_DOWNLOAD.request);
  const handleDownload = useCallback((project: Project) => downloadReport(undefined, project), [downloadReport]);

  const removeProjectState = useSelector(getProjectListRemoving);
  const removeProjectOpen = useAction(PROJECT_LIST_REMOVE_CONFIRM.trigger);
  const removeProjectSubmit = useBoundAction(PROJECT_LIST_REMOVE_SUBMIT.request, undefined);
  const removeProjectCancel = useBoundAction(PROJECT_LIST_REMOVE_CANCEL.trigger, undefined);

  const unfollowProjectState = useSelector(getProjectListUnfollowing);

  const unfollowProjectOpen = useAction(PROJECT_LIST_UNFOLLOW_CONFIRM.trigger);
  const unfollowProjectSubmit = useAction(PROJECT_LIST_UNFOLLOW_SUBMIT.request);
  const unfollowProjectCancel = useAction(PROJECT_LIST_UNFOLLOW_CANCEL.trigger);

  const importState = useSelector(getProjectListImport);
  const importProjectOpen = useBoundAction(PROJECT_LIST_IMPORT_OPEN.trigger, undefined);
  const importProjectUpload = useAction(PROJECT_LIST_IMPORT_UPLOAD.request);
  const importProjectCancel = useBoundAction(PROJECT_LIST_IMPORT_CANCEL.trigger, undefined);
  const importProjectSubmit = useAction(PROJECT_LIST_IMPORT_SUBMIT.request);
  const importProjectComplete = useBoundAction(PROJECT_LIST_IMPORT_COMPLETE.trigger, undefined);

  const exporting = useSelector(getProjectListExport);
  const exportProjectData = useBoundAction(PROJECT_LIST_EXPORT.request, undefined);

  const shareProject = useAction(PROJECT_LIST_SHARE.trigger);

  return (
    <>
      <Helmet>
        <title>{
          openCreationDialog
            ? intl.formatMessage({ id: 'projectList/pageTitle/newProject' })
            : intl.formatMessage({ id: 'projectList/pageTitle' })
        }
        </title>
      </Helmet>

      <AppLayout classNameBody={clsx({ 'bp-project-list-page__body--empty': data.length === 0 })}>
        {data.length !== 0 ? (
          <>
            <ProjectListToolbar
              query={query}
              exporting={exporting}
              onExport={exportProjectData}
              onImport={importProjectOpen}
              onCreate={() => setOpenCreationDialog(true)}
              onFilter={applyFilter}
            />

            <ProjectListView
              query={query}
              projects={slice}
              downloading={downloading}
              unfollowing={unfollowProjectState}
              onRemove={removeProjectOpen}
              onDownload={handleDownload}
              onUnfollow={(project) => unfollowProjectOpen(project, user)}
              onShare={shareProject}
            />
          </>
        ) : (
          <ProjectListEmpty onImport={importProjectOpen} onCreate={() => setOpenCreationDialog(true)}/>
        )}
      </AppLayout>

      <ProjectListRemoveModal
        data={removeProjectState}
        onCancel={removeProjectCancel}
        onSubmit={removeProjectSubmit}
      />

      <ProjectCreateModal
        openCreationDialog={openCreationDialog}
        onCancel={(): void => setOpenCreationDialog(false)}
      />

      <ProjectListImportModal
        data={importState}
        onUpload={importProjectUpload}
        onCancel={importProjectCancel}
        onSubmit={importProjectSubmit}
        onComplete={importProjectComplete}
      />

      <UnfollowProjectModal
        data={unfollowProjectState}
        onClose={unfollowProjectCancel}
        onConfirmUnfollow={unfollowProjectSubmit}
      />

      <ShareProject/>
    </>
  );
};
