import { SagaIterator } from 'redux-saga';
import {
  SCHEMA_COMMENT_CHANGE,
  SCHEMA_MATRIX_CHANGE,
  SCHEMA_TEMPLATE_SELECT,
  SCHEMA_VERSION_CHANGE,
  SCHEMA_VERSION_CREATE,
  SCHEMA_VERSION_REMOVE,
  SCHEMA_SELECTION_CHANGE,
} from 'src/modules/schema/actions/SchemaActions';
import { schemaCommentChangeSaga } from 'src/modules/schema/sagas/schemaCommentChangeSaga';
import { schemaMatrixChangeSaga } from 'src/modules/schema/sagas/schemaMatrixChangeSaga';
import { schemaSelectionChangeSaga } from 'src/modules/schema/sagas/schemaSelectionChangeSaga';
import { schemaTemplateSelectSaga } from 'src/modules/schema/sagas/schemaTemplateSelectSaga';
import { schemaVersionChangeSaga } from 'src/modules/schema/sagas/schemaVersionChangeSaga';
import { schemaVersionCreateSaga } from 'src/modules/schema/sagas/schemaVersionCreateSaga';
import { schemaVersionRemoveSaga } from 'src/modules/schema/sagas/schemaVersionRemoveSaga';
import { takeLatest } from 'typed-redux-saga';

export function* schemaSaga(): SagaIterator<void> {
  yield* takeLatest(SCHEMA_TEMPLATE_SELECT.is, schemaTemplateSelectSaga);
  yield* takeLatest(SCHEMA_VERSION_CREATE.is, schemaVersionCreateSaga);
  yield* takeLatest(SCHEMA_VERSION_CHANGE.is, schemaVersionChangeSaga);
  yield* takeLatest(SCHEMA_VERSION_REMOVE.is, schemaVersionRemoveSaga);
  yield* takeLatest(SCHEMA_COMMENT_CHANGE.is, schemaCommentChangeSaga);
  yield* takeLatest(SCHEMA_MATRIX_CHANGE.is, schemaMatrixChangeSaga);
  yield* takeLatest(SCHEMA_SELECTION_CHANGE.isRequest, schemaSelectionChangeSaga);
}
