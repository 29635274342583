import { SagaIterator } from 'redux-saga';
import { Organization } from 'src/modules/common/types/Organization';
import { WHOAMI_ACTION } from 'src/modules/login/actions/WhoAmIAction';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { put, select } from 'typed-redux-saga';

export function* syncUserOrganization(organization: Organization): SagaIterator<void> {
  const user = yield* select(getLoggedInUser);
  yield* put(WHOAMI_ACTION.trigger({ ...user, organization }));
}
