import './SchemaSelectScreen.scss';
import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { InlineNotification } from '@carbon/react';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { SchemaCard } from 'src/modules/schema/views/SchemaCard';

type Props = {
  readonly area: SchemaArea;
  readonly templates: ReadonlyArray<ConfigSchema>;
  readonly selection: ConfigSchema | null;
  readonly onSelect: (selection: ConfigSchema) => void;
  readonly onCreate: () => void;
};

export const SchemaSelectScreen = ({
  area,
  templates,
  selection,
  onSelect,
  onCreate,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const cardRefs = useRef<{ [key: ConfigSchema['id']]: HTMLButtonElement | null }>({});

  useEffect(() => {
    if (selection && cardRefs.current[selection.id]) {
      cardRefs.current[selection.id]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selection]);

  return templates.length !== 0 ? (
    <div className="bp-schema-select-screen">
      <h2 className="bp-schema-select-screen__title">
        <FormattedMessage
          id="schemaSelection/pageTitle"
          values={{ area: intl.formatMessage({ id: `schemaSelection/area/${area}` }) }}
        />
      </h2>

      <HorizontalDivider className="bp-schema-select-screen__divider"/>

      <div className="bp-schema-select-screen__actions">
        <CustomButton
          className="bp-multiple-schemas-screen__button"
          disabled={selection === null}
          onClick={onCreate}
          size="md"
        >
          <FormattedMessage id="schemaSelection/action/create"/>
        </CustomButton>
      </div>

      <div className="bp-schema-select-screen__content">
        <div className="bp-schema-select-screen__schema">
          <div className="bp-schema-select-screen__schema-options">
            {templates.map((schema) => (
              <SchemaCard
                ref={(el) => {
                  cardRefs.current[schema.id] = el;
                }}
                key={schema.id}
                schema={schema}
                selected={schema === selection}
                onSelect={onSelect}
              />
            ))}
          </div>

          <div className="bp-schema-select-screen__schema-container">
            {selection && (
              <div className="bp-schema-select-screen__schema-preview">
                <div className="bp-schema-select-screen__schema-info">
                  <h2 className="bp-schema-select-screen__schema-title">
                    {selection.id}
                  </h2>

                  {selection.explanation && (
                    <p className="bp-schema-select-screen__schema-description">
                      <FormattedMessage id={selection.explanation}/>
                    </p>
                  )}
                </div>

                <div className="bp-schema-select-screen__schema-image">
                  <img
                    src={`/images/schemas/${encodeURIComponent(selection.id)}.svg`}
                    alt="schema"
                    style={{
                      display: 'block',
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <InlineNotification
      className="bp-schema-select-screen__notification"
      kind="warning"
      title={intl.formatMessage({ id: 'schemaSelection/filter/noSchemasMessage' })}
      hideCloseButton={true}
    />
  );
};
