import './OutputDocument.scss';
import React, { useContext, useLayoutEffect } from 'react';
import { OutputDocumentContext } from 'src/modules/output/context/OutputDocumentContext';

type Props = {
  readonly id: string;
  readonly order: number;
  readonly section: string;
  readonly visible: boolean;
  readonly children: React.ReactElement;
};

export const OutputDocumentPage = ({ id, order, section, visible, children }: Props): React.ReactElement => {
  const { registerPage, unregisterPage } = useContext(OutputDocumentContext);

  useLayoutEffect(() => {
    registerPage({
      id: id,
      order: order,
      section: section,
      visible: visible,
      content: children,
    });
  }, [children, id, order, section, visible, registerPage]);

  useLayoutEffect(() => () => unregisterPage(id), [id, unregisterPage]);

  return children;
};
