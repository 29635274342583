import './EvaluationResultsChartsGraph.scss';
import { EvaluationResultsChartsCO2, HvacArea } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import { literal } from 'io-ts/es6/Guard';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { SavingsValue } from 'src/modules/common/components/SavingsValue';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { EvaluationResultsChartsXTick } from 'src/modules/evaluation/views/EvaluationResultsChartsXTick';
import { isNotNull } from 'src/utils/guard';

type Props = {
  readonly data: EvaluationResultsChartsCO2 | null;
  readonly interactive: boolean;
  readonly shouldDisplayOverallData: boolean;
  readonly yAxisLabel: React.ReactNode;
};

export const EvaluationResultsChartsGraphCO2 = memo((
  { data, interactive, shouldDisplayOverallData, yAxisLabel }: Props,
) => {
  const intl = useIntl();

  const areaNames = useMemo(() => ({
    overall: intl.formatMessage({ id: 'evaluation/charts/CO2/values/overall' }),
    heating: intl.formatMessage({ id: 'evaluation/hvacArea/heating' }),
    cooling: intl.formatMessage({ id: 'evaluation/hvacArea/cooling' }),
    ventilation: intl.formatMessage({ id: 'evaluation/hvacArea/ventilation' }),
  }), [intl]);

  const chartData = useMemo(() => {
    if (data === null) {
      return [];
    }

    if (!shouldDisplayOverallData) {
      return HVAC_AREAS.map((area) => {
        const values = data.perArea[area];
        if (values === null) {
          return null;
        }

        return {
          area: area,
          actual: values.actual.div(1_000_000).toNumber(),
          future: values.future.div(1_000_000).toNumber(),
        };
      }).filter(isNotNull);
    }

    return [
      ...HVAC_AREAS.map((area) => {
        const values = data.perArea[area];
        if (values === null) {
          return null;
        }

        return {
          area: area,
          actual: values.actual.div(1_000_000).toNumber(),
          future: values.future.div(1_000_000).toNumber(),
        };
      }).filter(isNotNull),
      {
        area: 'overall' as const,
        actual: data.overall.actual.div(1_000_000).toNumber(),
        future: data.overall.future.div(1_000_000).toNumber(),
      },
    ];
  }, [data, shouldDisplayOverallData]);

  const [isChartReady, setChartReady] = useState(chartData.length === 0);

  const renderTooltip = useCallback((props: TooltipProps<ValueType, NameType>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { label } = props;

    const value = chartData.find((it) => it.area === label);
    if (value === undefined) {
      return null;
    }

    return (
      <div className="bp-evaluation-results-charts-graph__tooltip">
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: 'none' }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            {HVAC_NAMES[value.area]}
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: COLORS.actual }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            <FormattedMessage
              id="evaluation/charts/CO2/values/actual"
              values={{ sub, sup }}
            />
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <SavingsValue
              value={new Decimal(value.actual)}
              precision={0}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: COLORS.future }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            <FormattedMessage
              id="evaluation/charts/CO2/values/future"
              values={{ sub, sup }}
            />
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <SavingsValue
              value={new Decimal(value.future)}
              precision={0}
            />
          </div>
        </div>
      </div>
    );
  }, [chartData]);

  const renderLegend = useMemo(() => (
    <div className="bp-evaluation-results-charts-graph__legend-wrapper">
      <div className="bp-evaluation-results-charts-graph__legend">
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.actual }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            <FormattedMessage
              id="evaluation/charts/CO2/values/actual"
              values={{ sub, sup }}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.future }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            <FormattedMessage
              id="evaluation/charts/CO2/values/future"
              values={{ sub, sup }}
            />
          </div>
        </div>
      </div>
    </div>
  ), []);

  return (
    <div className="bp-evaluation-results-charts-graph__graph-wrapper" data-ready={isChartReady} data-type="CO2">
      <div className="bp-evaluation-results-charts-graph__graph">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            barGap="1%"
            barSize="3%"
          >
            <CartesianGrid
              vertical={false}
              stroke="#C3C3C3"
              strokeWidth={1}
              strokeDasharray="3 3"
            />

            {interactive && (
              <Tooltip
                isAnimationActive={false}
                content={renderTooltip}
                cursor={{
                  fill: 'none',
                  stroke: 'none',
                }}
              />
            )}

            <Bar
              onAnimationEnd={() => setChartReady(true)}
              dataKey="actual"
              fill={COLORS.actual}
              fillOpacity={1}
              barSize={shouldDisplayOverallData ? 20 : 40}
            />
            <Bar
              onAnimationEnd={() => setChartReady(true)}
              dataKey="future"
              fill={COLORS.future}
              fillOpacity={1}
              barSize={shouldDisplayOverallData ? 20 : 40}
            />

            <XAxis
              dataKey="area"
              fontSize={12}
              interval={0}
              tickFormatter={(value) => (
                SERIA_KEYS.is(value)
                  ? areaNames[value]
                  : ''
              )}
              tick={EvaluationResultsChartsXTick}
            />

            <YAxis
              fontSize={12}
            />
          </BarChart>
        </ResponsiveContainer>

        {renderLegend}

        <div className="bp-evaluation-results-charts-graph__y-label">
          <p className="bp-evaluation-results-charts-graph__y-label-text">
            {yAxisLabel}
          </p>
        </div>
      </div>
    </div>
  );
});

const COLORS = {
  actual: '#BDD2DF',
  future: '#632E5B',
};

const HVAC_AREAS = [
  HvacArea.HEATING,
  HvacArea.COOLING,
  HvacArea.VENTILATION,
];

const HVAC_NAMES = {
  overall: <FormattedMessage id="evaluation/charts/CO2/values/overall"/>,
  heating: <FormattedMessage id="evaluation/hvacArea/heating"/>,
  cooling: <FormattedMessage id="evaluation/hvacArea/cooling"/>,
  ventilation: <FormattedMessage id="evaluation/hvacArea/ventilation"/>,
};

const SERIA_KEYS = literal(
  'overall',
  'heating',
  'cooling',
  'ventilation',
);
