import './OrganizationActions.scss';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { FormError } from 'src/modules/form/types/FormError';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';

type Props = {
  readonly form: FormController<OrganizationFormData, FormError>;
  readonly onSubmit: () => void;
};

export const OrganizationActions = ({ form, onSubmit }: Props): React.ReactElement => {
  const isSubmitting = useFormWatch(form, form.submitting.get);

  return (
    <div className="bp-organization-actions">
      <CustomButton
        kind="primary"
        onClick={onSubmit}
        loading={isSubmitting}
        size="md"
        type="button"
      >
        <FormattedMessage id="organization/settings/submit"/>
      </CustomButton>
    </div>
  );
};
