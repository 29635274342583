import { NativeError, PROJECT_DATA, ProjectData, RuntimeError, Uuid } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { PROJECT } from 'src/modules/common/codecs/Project';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { Project } from 'src/modules/common/types/Project';
import { call } from 'typed-redux-saga';

export function* updateProject(id: Uuid, project: ProjectData): SagaIterator<Project> {
  try {
    yield* call(updateUserLatestActivity);

    const body = yield* call(PROJECT_DATA.encode, project);
    const { content } = yield* call(makeRestRequest, 'PUT', `/projects/${encodeURIComponent(id)}`, body);
    return yield* call(decodeOrThrow, PROJECT, content);
  } catch (error) {
    throw new RuntimeError(`Could not update project "${id}"`, { project }, NativeError.wrap(error));
  }
}
