import { FieldPath } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { Rating } from 'src/modules/common/components/Rating';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData> = {
  readonly disabled: boolean;
  readonly field: FieldPath<TData, FormError, number | null>;
};

export const RatingField: <TData>(props: Props<TData>) => React.ReactElement = (
  { disabled, field },
) => {
  const handleChange = useCallback((newRating: number) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(newRating),
    ));
  }, [field]);

  return (
    <Rating onSelect={handleChange} disabled={disabled}/>
  );
};
