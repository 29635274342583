import { FormController } from '@form-ts/core';
import { FormError } from 'src/modules/form/types/FormError';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const ORGANIZATION_WATCH =
  createTrigger<void>('ORGANIZATION_WATCH');

export const ORGANIZATION_CHANGE =
  createActions<FormController<OrganizationFormData, FormError>, void>('ORGANIZATION_CHANGE');
