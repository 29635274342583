import './ReportDocumentPageCover.scss';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AreaUnitView } from 'src/modules/common/components/AreaUnitView';
import { AssetImage } from 'src/modules/common/components/AssetImage';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';
import { ProjectTitle } from 'src/modules/common/components/ProjectTitle';
import { OutputDocumentPage } from 'src/modules/output/views/OutputDocumentPage';
import { OutputLogo } from 'src/modules/output/views/OutputLogo';
import { OutputPage } from 'src/modules/output/views/OutputPage';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

export const ReportDocumentPageCover = ({ language, document, formData }: Props): React.ReactElement => (
  <OutputDocumentPage
    id="report-cover"
    order={1_000}
    section="cover"
    visible={true}
  >
    <OutputPage language={language} showPageNumber={false}>
      <div className="bp-report-document-page-cover">
        <div className="bp-report-document-page-cover__brand">
          {document.cover.image !== null && (
            <AssetImage
              asset={document.cover.image}
              size="large"
              className="bp-report-document-page-cover__brand-cover"
            />
          )}
          {document.cover.image === null && (
            <div className="bp-report-document-page-cover__brand-cover"/>
          )}

          <div className="bp-report-document-page-cover__brand-name">
            <ProjectTitle title={document.cover.title}/>
          </div>
        </div>

        <div className="bp-report-document-page-cover__title">
          <FormattedMessage id="report/document/cover/title"/>
        </div>

        <div className="bp-report-document-page-cover__description">
          <div className="bp-report-document-page-cover__description-goals">
            {document.cover.logo && formData.cover.showOrganizationLogo && (
              <div className="bp-report-document-page-cover__organization">
                <p className="bp-report-document-page-cover__organization-title">
                  <FormattedMessage id="report/document/cover/organization/title"/>
                </p>

                <AssetImage
                  asset={document.cover.logo}
                  size="large"
                  className="bp-report-document-page-cover__organization-logo"
                />
              </div>
            )}

            {document.cover.goals.trim() && (
              <>
                <div className="bp-report-document-page-cover__description-goals-title">
                  <FormattedMessage id="report/document/cover/goals"/>
                </div>

                <div className="bp-report-document-page-cover__description-goals-text">{document.cover.goals}</div>
              </>
            )}
          </div>

          <div className="bp-report-document-page-cover__description-details">
            {formData.cover.showBuildingInfo && (
              <div className="bp-report-document-page-cover__description-details-section">
                <div className="bp-report-document-page-cover__description-details-title">
                  <FormattedMessage id="report/document/cover/building"/>
                </div>

                <div className="bp-report-document-page-cover__description-details-text">
                  <FormattedMessage id={`common/buildingType/${document.cover.building.type}`}/>
                </div>

                {document.cover.building.size && (
                  <div className="bp-report-document-page-cover__description-details-text">
                    <FormattedValue
                      value={document.cover.building.size}
                      precision={0}
                    />
                    <AreaUnitView unit={document.cover.building.unit}/>
                  </div>
                )}

                <div className="bp-report-document-page-cover__description-details-text">
                  {document.cover.building.address.street}
                </div>

                <div className="bp-report-document-page-cover__description-details-text">
                  {document.cover.building.address.zip} {document.cover.building.address.city}
                  {', '}
                  <FormattedMessage id={`common/country/name/${document.cover.building.address.country}`}/>
                </div>
              </div>
            )}

            <div className="bp-report-document-page-cover__description-details-section">
              <div className="bp-report-document-page-cover__description-details-title">
                <FormattedMessage id="report/document/cover/creator"/>
              </div>

              <div className="bp-report-document-page-cover__description-details-text">
                {document.cover.author}
              </div>

              <div className="bp-report-document-page-cover__description-details-text">
                {document.cover.email}
              </div>
            </div>

            <div className="bp-report-document-page-cover__description-details-section">
              <div className="bp-report-document-page-cover__description-details-title">
                <FormattedMessage id="report/document/cover/creationDate"/>
              </div>

              <div className="bp-report-document-page-cover__description-details-text">
                {dayjs(document.cover.reportDate).format('DD.MM.YYYY')}
              </div>
            </div>
          </div>
        </div>

        <div className="bp-report-document-page-cover__footer">
          <div className="bp-report-document-page-cover__footer-company">
            <div className="bp-report-document-page-cover__footer-title">
              {document.cover.organization}
            </div>

            <div className="bp-report-document-page-cover__footer-contacts">
              <div className="bp-report-document-page-cover__footer-contact">
                {document.cover.address.street}
                <br/>
                <FormattedMessage id="report/document/cover/location" values={document.cover.address}/>
              </div>

              <div className="bp-report-document-page-cover__footer-contact">
                {document.cover.contact.phone}
                <br/>
                {document.cover.contact.email}
              </div>

              <div className="bp-report-document-page-cover__footer-contact">
                {document.cover.contact.website}
              </div>
            </div>
          </div>

          <div className="bp-report-document-page-cover__footer-logo">
            <OutputLogo/>
          </div>
        </div>
      </div>
    </OutputPage>
  </OutputDocumentPage>
);
