import { SagaIterator } from 'redux-saga';
import {
  ORGANIZATION_CHANGE,
  ORGANIZATION_WATCH,
} from 'src/modules/organization/actions/OrganizationActions';
import { organizationChangeSaga } from 'src/modules/organization/sagas/organizationChangeSaga';
import { ROUTE_CHANGE } from 'src/modules/routing/actions/RouteChange';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* organizationSaga(): SagaIterator<void> {
  while (true) {
    yield* take(ORGANIZATION_WATCH.is);
    const task = yield* takeLatest(ORGANIZATION_CHANGE.isRequest, organizationChangeSaga);

    yield* take(ROUTE_CHANGE.isPending);
    yield* cancel(task);
  }
}
