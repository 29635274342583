import './FeedbackForm.scss';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeedbackFormData } from 'src/modules/feedback/types/FeedbackFormData';
import { CheckboxField } from 'src/modules/form/components/CheckboxField';
import { RatingField } from 'src/modules/form/components/RatingField';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly form: FormController<FeedbackFormData, FormError>;
};

export const FeedbackForm = ({ form }: Props): React.ReactElement => {
  const intl = useIntl();
  const submitting = useFormWatch(form, form.submitting.get);

  return (
    <form
      className="bp-feedback-form"
    >
      <div className="bp-feedback-form__rating">
        <h6 className="bp-feedback-form__title">
          <FormattedMessage id="feedback/modal/form/rating"/>
        </h6>

        <RatingField
          field={form.field.at('rating')}
          disabled={submitting}
        />
      </div>

      <TextAreaField
        labelText=""
        theme="white"
        field={form.field.at('comment')}
        placeholder={intl.formatMessage({ id: 'feedback/modal/form/comment' })}
        disabled={submitting}
        maxLength={500}
        rows={3}
        showHint={true}
      />

      <CheckboxField
        field={form.field.at('contact')}
        labelText={intl.formatMessage({ id: 'feedback/modal/form/contact' })}
        disabled={submitting}
      />
    </form>
  );
};
