import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';
import { announcementsReducer } from 'src/modules/announcements/reducers/announcementsReducer';
import { assetReducer } from 'src/modules/asset/reducers/assetReducer';
import { configReducer } from 'src/modules/config/reducers/configReducer';
import { loginReducer } from 'src/modules/login/reducers/loginReducer';
import { modalStackReducer } from 'src/modules/modals/reducers/modalStackReducer';
import { notificationReducer } from 'src/modules/notifications/reducers/notificationReducer';
import { passwordResetReducer } from 'src/modules/password-reset/reducers/passwordResetReducer';
import { projectListReducer } from 'src/modules/project-list/reducers/projectListReducer';
import { projectSyncReducer } from 'src/modules/project-sync/reducers/projectSyncReducer';
import { projectViewReducer } from 'src/modules/project-view/reducers/projectViewReducer';
import { reportReducer } from 'src/modules/report/reducers/reportReducer';
import { routingReducer } from 'src/modules/routing/reducers/routingReducer';
import { schemaReducer } from 'src/modules/schema/reducers/schemaReducer';
import { scrollReducer } from 'src/modules/scroll/reducers/scrollReducer';
import { shareProjectReducer } from 'src/modules/sharing-projects/reducers/shareProjectReducer';
import { usersReducer } from 'src/modules/users/reducers/usersReducer';
import { RootState } from 'src/types/RootState';

export const rootReducer = (
  history: History,
): Reducer<RootState> => combineReducers<RootState>({
  router: connectRouter(history),
  routing: routingReducer,

  scroll: scrollReducer,

  config: configReducer,
  login: loginReducer,
  asset: assetReducer,
  report: reportReducer,
  modals: modalStackReducer,
  schema: schemaReducer,

  projectList: projectListReducer,
  projectView: projectViewReducer,
  projectSync: projectSyncReducer,
  notification: notificationReducer,
  announcements: announcementsReducer,

  users: usersReducer,
  shareProject: shareProjectReducer,
  passwordReset: passwordResetReducer,
});
