import './HvacFormSetupOption.scss';
import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import clsx from 'clsx';
import React from 'react';
import { EnergyEfficiencyLabel } from 'src/modules/common/components/EnergyEfficiencyLabel';
import { LABELS } from 'src/modules/common/constants/energyEfficiencyLabels';

type Props = {
  readonly label: React.ReactNode;
  readonly comment: React.ReactNode;
  readonly renderComment: boolean;
  readonly renderEfficiencyLabel?: boolean;
  readonly energyEfficiencyClass?: EnergyEfficiencyClass;
};

export const HvacFormSetupOption = ({
  label,
  comment,
  renderComment,
  renderEfficiencyLabel,
  energyEfficiencyClass,
}: Props): React.ReactElement => (
  <div className={clsx(
    'bp-hvac-form-setup-option',
    { 'bp-hvac-form-setup-option--with-comment': renderComment && comment },
  )}
  >
    {renderEfficiencyLabel && (
      <div className="bp-hvac-form-setup-option__label">
        <EnergyEfficiencyLabel value={energyEfficiencyClass || null} sized={false}>
          {energyEfficiencyClass ? LABELS[energyEfficiencyClass] : '\u2013' }
        </EnergyEfficiencyLabel>
      </div>
    )}

    <div className="bp-hvac-form-setup-option__content">
      <div className="bp-hvac-form-setup-option__title-wrapper">
        <div className="bp-hvac-form-setup-option__title">
          {label}
        </div>
      </div>

      {(renderComment && comment) && (
        <div className="bp-hvac-form-setup-option__comment">
          {comment}
        </div>
      )}
    </div>
  </div>
);
