import { Action } from 'redux';
import {
  SCHEMA_SELECTION_CHANGE,
  SCHEMA_SELECTION_FETCH,
} from 'src/modules/schema/actions/SchemaActions';
import { SchemaState } from 'src/modules/schema/types/SchemaState';

export function schemaSelectionReducer(state: SchemaState, action: Action): SchemaState {
  if (SCHEMA_SELECTION_FETCH.is(action)) {
    return {
      ...state,
      selection: action.data,
    };
  }

  if (SCHEMA_SELECTION_CHANGE.isSuccess(action)) {
    return {
      ...state,
      selection: action.data,
    };
  }

  return state;
}
