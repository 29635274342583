import { NativeError, RuntimeError, ProjectData, PROJECT_DATA } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { PROJECT } from 'src/modules/common/codecs/Project';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { Project } from 'src/modules/common/types/Project';
import { call } from 'typed-redux-saga';

export function* createProject(project: ProjectData): SagaIterator<Project> {
  try {
    yield* call(updateUserLatestActivity);

    const body = yield* call(PROJECT_DATA.encode, project);
    const { content } = yield* call(makeRestRequest, 'POST', '/projects', body);
    return yield* call(decodeOrThrow, PROJECT, content);
  } catch (error) {
    throw new RuntimeError('Could not create project', { project }, NativeError.wrap(error));
  }
}
