import './AssetField.scss';
import { Close } from '@carbon/icons-react';
import { IconButton } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AssetUpload } from 'src/modules/asset/views/AssetUpload';
import { AssetImage } from 'src/modules/common/components/AssetImage';
import { FILE_MAX_SIZE_FILED_MB } from 'src/modules/common/constants/fileField';
import { Asset } from 'src/modules/common/types/Asset';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData> = {
  readonly theme?: 'gray' | 'white';
  readonly field: FieldPath<TData, FormError, Asset | null>;
  readonly disabled?: boolean;
};

export const AssetField: <TData>(props: Props<TData>) => React.ReactElement = ({
  theme = 'gray',
  field,
  disabled = false,
}) => {
  const intl = useIntl();
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((asset: Asset) => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
      field.value.set(asset),
    ));
  }, [field]);

  const handleReset = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
      field.value.set(null),
    ));
  }, [field]);

  const handleTouch = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  if (value !== null) {
    return (
      <div className={clsx(
        'bp-asset-field',
        'bp-asset-field--filled',
        `bp-asset-field--${theme}`,
      )}
      >
        <AssetImage
          asset={value}
          size="large"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
          }}
        />

        <div className="bp-asset-field__icon-wrapper">
          <IconButton
            kind="secondary"
            className="bp-asset-field__cancel-button"
            label={intl.formatMessage({ id: 'fileUpload/reset' })}
            align="left"
            onClick={handleReset}
            disabled={disabled}
          >
            <Close size={24}/>
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <div className="bp-asset-field-wrapper">
      <div className={clsx(
        'bp-asset-field',
        `bp-asset-field--${theme}`,
        {
          'bp-asset-field--invalid': invalid,
        },
      )}
      >
        <AssetUpload
          id={`${field.form.name}.${field.path}`}
          name={field.path}
          disabled={disabled}
          onTouch={handleTouch}
          onChange={handleChange}
        >
          <p className="bp-asset-field__placeholder">
            <FormattedMessage id="fileUpload/placeholder"/>
          </p>
        </AssetUpload>
      </div>

      <p className="bp-asset-field__hint">
        <FormattedMessage
          id="form/field/file/maxSize"
          values={{ maxSize: FILE_MAX_SIZE_FILED_MB }}
        />
      </p>
    </div>
  );
};
