import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { ANNOUNCEMENTS } from 'src/modules/common/codecs/Announcement';
import { updateUserLatestActivity } from 'src/modules/common/sagas/utils/updateUserLatestActivity';
import { Announcement } from 'src/modules/common/types/Announcement';
import { call } from 'typed-redux-saga';

export function* loadAnnouncements(): SagaIterator<ReadonlyArray<Announcement>> {
  try {
    yield* call(updateUserLatestActivity);

    const { content } = yield* call(makeRestRequest, 'GET', '/notifications/announcements');
    return yield* call(decodeOrThrow, ANNOUNCEMENTS, content);
  } catch (error) {
    throw new RuntimeError('Could not load announcements', {}, NativeError.wrap(error));
  }
}
