import { COUNTRY_CODES, CountryCode } from '@belimo-retrofit-portal/logic';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { INTL_LOCALE_MAP } from 'src/modules/common/constants/language';
import { AutocompleteOption } from 'src/modules/common/types/AutocompleteOption';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';

export function useCountryList(): ReadonlyArray<AutocompleteOption<CountryCode>> {
  const intl = useIntl();
  const language = useSelector(getLanguage);
  const collator = useMemo(() => new Intl.Collator(INTL_LOCALE_MAP[language]), [language]);

  return useMemo(
    () => COUNTRY_CODES.map((code) => ({
      value: code,
      label: intl.formatMessage({ id: `common/country/name/${code}` }),
    })).sort((optionA, optionB) => collator.compare(optionA.label, optionB.label)),
    [intl, collator],
  );
}
